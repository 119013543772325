.dollar-input-container{
  position: relative;
  input[type="text"] {
    padding-right: 4.5rem;
  }

  .checkControl {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
  }
  span.title{
   position: relative;
   top: -0.08rem;
   right: 0.15rem; 
  }
}