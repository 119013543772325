@import '../utils/variables.scss';
@import '../utils/mixins.scss';
@import '../utils/functions';

// Bid, Quotes & others colors changes
@mixin headerMenu($navBar, $active) {
	header {
		nav {
			&.navbar {
				@include border(0, 0, 4, 0, solid, $navBar);
			}

			&.navbar-light {
				.navbar-nav {
					.nav-link {
						&:hover {
						}

						&.active {
							border-color: $active;
						}
					}
				}
			}
		}
	}
}

@mixin contentWrapper($sideBar, $contentArea, $arrowColor, $arrowText: null) {
	main {
		.sideBarSpacing {
			@include border(4, 0, 0, 0, solid, $sideBar);
		}

		.colWrapper {
			@include border(4, 0, 0, 0, solid, $contentArea);
		}
	}

	// Arrow Color
	.arrowWrapper {
		background: $arrowColor;
		color: $arrowText;

		&:before {
			background: -webkit-linear-gradient(left bottom, $arrowColor 48.5%, rgba(0, 0, 0, 0) 50%);
			background: linear-gradient(to right top, $arrowColor 48.5%, rgba(0, 0, 0, 0) 50%);
			// border-color: transparent $arrowColor transparent transparent;
		}

		&:after {
			background: -webkit-linear-gradient(left top, $arrowColor 48.5%, rgba(0, 0, 0, 0) 50%);
			background: linear-gradient(to right bottom, $arrowColor 48.5%, rgba(0, 0, 0, 0) 50%);
		}
	}
}

// Tab content
@mixin tabArea($tabNormal, $tabActive, $borderColor) {
	.nav-tabs {
		border: none;
		.nav-item {
			.nav-link {
				border-bottom-color: $borderColor;

				&.active {
					border-color: $borderColor !important;
				}
			}
		}
	}

	.tab-content {
		border-top-color: $borderColor;
	}
}

@mixin themable($theme-name, $themeConfig) {
	body.#{$theme-name} {
		color: map-deep-get($themeConfig, 'body', 'textColor');
	}

	.#{$theme-name} {
		// loader
		.overlay {
			background: map-deep-get($themeConfig, 'loader', 'bgColor');
			opacity: map-deep-get($themeConfig, 'loader', 'opacity');
		}

		// Body BG
		@if (map-deep-get($themeConfig, 'body', 'gradent') ==true) {
			// background-image: linear-gradient(map-deep-get($themeConfig, 'body', 'bgColor'),
			//         map-deep-get($themeConfig, 'body', 'bgColor2'));
			background: transparent
				linear-gradient(
					darken(map-deep-get($themeConfig, 'body', 'bgColor2'), 19%),
					map-deep-get($themeConfig, 'body', 'bgColor2')
				);
		} @else {
			background: map-deep-get($themeConfig, 'body', 'bgColor');
		}

		// Default color chages in pages like dashboard,activity & messages(menu and content area border)
		.defaultThem {
			// Header
			@include headerMenu(
				map-deep-get($themeConfig, 'default', 'dark'),
				map-deep-get($themeConfig, 'default', 'medium')
			);

			// content area
			@include contentWrapper(
				map-deep-get($themeConfig, 'default', 'dark'),
				map-deep-get($themeConfig, 'default', 'dark'),
				map-deep-get($themeConfig, 'default', 'light'),
				map-deep-get($themeConfig, 'default', 'text')
			);
			@include tabArea(null, null, map-deep-get($themeConfig, 'default', 'dark'));
		}

		// Bid color chages in pages like Bids flow(changes like menu and content area border)
		.bidThem {
			// Header
			@include headerMenu(map-deep-get($themeConfig, 'bid', 'dark'), map-deep-get($themeConfig, 'bid', 'light'));

			// content area
			@include contentWrapper(
				map-deep-get($themeConfig, 'bid', 'dark'),
				map-deep-get($themeConfig, 'bid', 'dark'),
				map-deep-get($themeConfig, 'bid', 'light'),
				map-deep-get($themeConfig, 'bid', 'text')
			);

			@include tabArea(null, null, map-deep-get($themeConfig, 'bid', 'dark'));
		}

		// Quotes color chages in pages like Quotes flow(changes like menu and content area border)
		.quotesThem {
			@include headerMenu(map-deep-get($themeConfig, 'quote', 'dark'), map-deep-get($themeConfig, 'quote', 'light'));

			// content area
			@include contentWrapper(
				map-deep-get($themeConfig, 'quote', 'dark'),
				map-deep-get($themeConfig, 'quote', 'dark'),
				map-deep-get($themeConfig, 'quote', 'dark'),
				map-deep-get($themeConfig, 'quote', 'text')
			);
			@include tabArea(null, null, map-deep-get($themeConfig, 'quote', 'dark'));
		}

		//helper classes
		.staticLink,
		.staticLink:hover,
		a,
		a:hover {
			color: map-deep-get($themeConfig, 'helper', 'linkColor') !important;
			&.bttn-accent {
				color: $white50 !important;
			}
			&.bttn-primary {
				color: $white50 !important;
			}
		}

		.staticTxt {
			color: map-deep-get($themeConfig, 'helper', 'staticTxt') !important;
		}
		.bg-purple {
			a {
				color: $lighter-teal !important;
			}
		}
		.noteImportant {
			background: map-deep-get($themeConfig, 'helper', 'noteBG');
		}

		.lineHR,
		.colWrapper h4 {
			&:after {
				border-color: map-deep-get($themeConfig, 'helper', 'lineHR');
			}
		}

		.noResultWrapper {
			background: map-deep-get($themeConfig, 'helper', 'noResult', 'bg');
			p {
				color: map-deep-get($themeConfig, 'helper', 'noResult', 'text');
				i {
					color: map-deep-get($themeConfig, 'helper', 'noResult', 'icon');
				}
			}
		}

		.text-red200 {
			@if ($theme-name == 'dark_DS') {
				color: $light-red !important;
			}
		}
		.maintananceText {
			font-size:calculateRem(21);
			text-align: center;
		}

		.warningBox {
			color: map-deep-get($themeConfig, 'helper', 'warning', 'text');
			@include border(1, 1, 1, 1, solid, map-deep-get($themeConfig, 'helper', 'warning', 'border'));
			& > i {
				color: map-deep-get($themeConfig, 'helper', 'warning', 'icon');
			}
			p {
				color: map-deep-get($themeConfig, 'helper', 'warning', 'text');
			}
		}

		.ulListWrapper {
			.list-unstyled {
				li {
					h6 {
						color: map-deep-get($themeConfig, 'helper', 'ulListWrapper', 'normal', 'heading');
					}
					span {
						color: map-deep-get($themeConfig, 'helper', 'ulListWrapper', 'normal', 'subHeading');
						i {
							color: map-deep-get($themeConfig, 'helper', 'ulListWrapper', 'normal', 'valueText');
						}
					}
					&.active {
						background: map-deep-get($themeConfig, 'helper', 'ulListWrapper', 'active', 'bg');
						h6 {
							color: map-deep-get($themeConfig, 'helper', 'ulListWrapper', 'active', 'heading');
						}
						span {
							color: map-deep-get($themeConfig, 'helper', 'ulListWrapper', 'active', 'subHeading');
							i {
								color: map-deep-get($themeConfig, 'helper', 'ulListWrapper', 'active', 'valueText');
							}
						}
					}
					&:hover {
						background: map-deep-get($themeConfig, 'helper', 'ulListWrapper', 'hover', 'bg');
						h6 {
							color: map-deep-get($themeConfig, 'helper', 'ulListWrapper', 'hover', 'heading');
						}
						span {
							color: map-deep-get($themeConfig, 'helper', 'ulListWrapper', 'hover', 'subHeading');
							i {
								color: map-deep-get($themeConfig, 'helper', 'ulListWrapper', 'hover', 'valueText');
							}
						}
					}
				}
			}
		}
		.listStyleCustom {
			li {
			}
			&.arrow {
				li {
					&:before {
						color: map-deep-get($themeConfig, 'helper', 'ulListWrapper', 'customList', 'arrowColor');
					}
				}
			}
		}

		.bg-sidebarGray {
			background-color: map-deep-get($themeConfig, 'helper', 'sideBarBG');
		}

		// Navigation
		header {
			.dropdown {
				.dropdown-menu {
					&.show {
						&[style] {
							// top: calculateRem(15) !important;
						}
					}
				}
			}
			nav,
			&.fixed-nav {
				&.navbar {
					background: map-deep-get($themeConfig, 'headerNav', 'bgColor');
					// color: map-deep-get($themeConfig, 'headerNav', 'textColor');
					@include border(0, 0, 4, 0, solid, map-deep-get($themeConfig, 'default', 'dark'));
				}

				&.navbar-light {
					.navbar-brand {
						svg {
							fill: map-deep-get($themeConfig, 'headerNav', 'logoColor');
						}
					}
					.navbar-toggler {
						@if ($theme-name == 'dark_DS') {
							background: map-deep-get($themeConfig, 'headerNav', 'logoColor');
						}
					}

					.navbar-nav {
						.nav-link {
							color: map-deep-get($themeConfig, 'headerNav', 'textColor') !important;

							&:hover {
								background: map-deep-get($themeConfig, 'headerNav', 'activeBG');
								color: map-deep-get($themeConfig, 'headerNav', 'activeText') !important;
								border-color: map-deep-get($themeConfig, 'default', 'medium');
							}
							// &:focus {
							// 	background: map-deep-get($themeConfig, 'headerNav', 'activeBG');
							// 	color: map-deep-get($themeConfig, 'headerNav', 'activeText') !important;
							// 	border-color: map-deep-get($themeConfig, 'default', 'medium');
							// }
							&.active {
								background: map-deep-get($themeConfig, 'headerNav', 'activeBG');
								color: map-deep-get($themeConfig, 'headerNav', 'activeText') !important;
								border-color: map-deep-get($themeConfig, 'default', 'medium');
							}
						}
					}

					.dropdown {
						&.btn-group-sm {
							button {
								color: map-deep-get($themeConfig, 'headerNav', 'textColor');
								box-shadow: none;
							}
						}
						.dropdown-toggle {
							color: map-deep-get($themeConfig, 'headerNav', 'textColor');
						}
						.dropdown-menu {
							background: map-deep-get($themeConfig, 'headerNav', 'bgColor');
							&[style] {
							}
							hr {
								border-color: lighten(map-deep-get($themeConfig, 'headerNav', 'textColor'), 30%);
							}
							.dropdown-item {
								color: map-deep-get($themeConfig, 'headerNav', 'textColor') !important;
								cursor: pointer;

								i {
									color: map-deep-get($themeConfig, 'headerNav', 'textColor') !important;
								}
								&:hover,
								&:focus {
									background: map-deep-get($themeConfig, 'headerNav', 'bgColor');
									// color: map-deep-get($themeConfig, 'headerNav', 'activeText') !important;
								}
								&.active {
									background: map-deep-get($themeConfig, 'headerNav', 'activeBG');
									color: map-deep-get($themeConfig, 'headerNav', 'activeText') !important;
								}
							}
						}
					}
				}
			}

			// &.fixed-nav {
			//     nav {
			//         &.navbar-light {
			//             .navbar-nav {
			//                 .nav-link {
			//                     color: map-deep-get($themeConfig, 'headerNav', 'textColor');

			//                     &.active {
			//                         background: map-deep-get($themeConfig, 'headerNav', 'activeBG');
			//                         color: map-deep-get($themeConfig, 'headerNav', 'activeText');
			//                     }
			//                 }
			//             }
			//         }
			//     }
			// }
		}

		// Breadcrumb
		.breadcrumbWrapper {
			background: map-deep-get($themeConfig, 'breadcrumb', 'bgColor');

			.breadcrumb {
				.breadcrumb-item {
					color: map-deep-get($themeConfig, 'breadcrumb', 'textColor');

					a {
						color: map-deep-get($themeConfig, 'breadcrumb', 'linkColor') !important;
					}

					&:before {
						color: map-deep-get($themeConfig, 'breadcrumb', 'linkColor');
					}
				}

				&.pageControl {
					li {
						background: map-deep-get($themeConfig, 'breadcrumb', 'controlBG');

						span,
						a {
							color: map-deep-get($themeConfig, 'breadcrumb', 'textColor');

							&.staticLink {
								color: map-deep-get($themeConfig, 'breadcrumb', 'controlText') !important;
								text-decoration: none;
							}
						}
					}
				}
			}
		}

		// content area
		main {
			.sideBarSpacing {
				@include border(4, 0, 0, 0, solid, map-deep-get($themeConfig, 'default', 'dark'));
				background: rgba(map-deep-get($themeConfig, 'contentArea', 'sideBarBG'), 0.9);
			}

			.colWrapper {
				@include border(4, 0, 0, 0, solid, map-deep-get($themeConfig, 'default', 'dark'));
				background: map-deep-get($themeConfig, 'contentArea', 'mainContentBG');
			}

			.listGroupWrapper {
				background: map-deep-get($themeConfig, 'contentArea', 'listItems', 'bg');
				border-color: map-deep-get($themeConfig, 'contentArea', 'listItems', 'border');

				h5 {
					a {
						color: map-deep-get($themeConfig, 'contentArea', 'listItems', 'h5');
					}
				}

				p {
					color: map-deep-get($themeConfig, 'contentArea', 'listItems', 'text');
				}

				ul {
					li {
						color: map-deep-get($themeConfig, 'contentArea', 'listItems', 'text');

						i {
							color: map-deep-get($themeConfig, 'contentArea', 'listItems', 'i');
						}
					}
				}
			}
		}

		.generateNewList {
			.sideBarSpacing {
				// background: rgba(map-deep-get($themeConfig, 'contentArea', 'sideBarBG'), 0.9);
				background: none;
			}
			.colWrapper {
				background: map-deep-get($themeConfig, 'contentArea', 'mainContentBG');
			}
		}

		// components
		// Tab
		.nav-tabs {
			.nav-item {
				.nav-link {
					background: map-deep-get($themeConfig, 'components', 'tab', 'navBG');
					border-color: map-deep-get($themeConfig, 'components', 'tab', 'navBorder');
					border-bottom-color: map-deep-get($themeConfig, 'bid', 'dark');

					&.active {
						background: map-deep-get($themeConfig, 'components', 'tab', 'contentBG') !important;
						border-top-color: map-deep-get($themeConfig, 'bid', 'dark') !important;
						color: map-deep-get($themeConfig, 'components', 'tab', 'navText');
						// @include boxShadow(0.8, -4, 2, $gray200);
					}
				}
			}
		}

		.tab-content {
			background: map-deep-get($themeConfig, 'components', 'tab', 'contentBG');
			border-color: map-deep-get($themeConfig, 'components', 'tab', 'contentBG');
			// border-top-color: map-deep-get($themeConfig, 'bid','dark');
		}

		// Table
		table {
			&.tableHData {
				background: map-deep-get($themeConfig, 'components', 'table', 'bgColor');

				td {
					border-color: map-deep-get($themeConfig, 'components', 'table', 'borderColor');
				}
			}

			color: map-deep-get($themeConfig, 'components', 'table', 'textColor');

			tr {
				@if ($theme-name == 'light_DS') {
					th {
						&:first-child {
							padding-left: calculateRem(0);
						}
					}

					td {
						&:first-child {
							padding-left: calculateRem(0);
						}
					}
				}
			}
		}

		// Modal Popup
		&.modal-open {
			.modal {
				.modal-dialog {
					&.ebidCampaign {
						.modal-content {
							.modal-header {
								// display: none;
								.close {
									display: none;
								}
							}
							.modal-body {
								padding-top: calculateRem(16);
								padding-bottom: calculateRem(16);
								h4 {
									margin-bottom: calculateRem(8);
								}
							}
						}
					}
					.modal-content {
						background: map-deep-get($themeConfig, 'contentArea', 'mainContentBG');
						border-color: map-deep-get($themeConfig, 'contentArea', 'mainContentBG');

						.modal-header {
							background: map-deep-get($themeConfig, 'components', 'modal', 'headerBG');
							color: map-deep-get($themeConfig, 'components', 'modal', 'headerTxt');

							.close {
								color: map-deep-get($themeConfig, 'components', 'modal', 'headerTxt');
							}
						}

						.modal-body {
							.legalAdsWrapper {
								header {
									background: map-deep-get($themeConfig, 'components', 'modal', 'splColor');
								}

								footer {
									background: map-deep-get($themeConfig, 'components', 'modal', 'splColor');
								}
							}
						}
					}
				}

				& ~ .modal-backdrop {
					background: map-deep-get($themeConfig, 'overLay', 'bgColor');

					&.show {
						opacity: map-deep-get($themeConfig, 'overLay', 'opacity');
					}
				}
			}
		}

		// paging
		.pagingWrapper {
			ul {
				li {
					color: map-deep-get($themeConfig, 'components', 'paging', 'numText');

					i {
						color: map-deep-get($themeConfig, 'components', 'paging', 'textColor');
					}

					&:hover {
						color: map-deep-get($themeConfig, 'components', 'paging', 'numActive');
						background: map-deep-get($themeConfig, 'components', 'paging', 'numBG');
					}

					&.active {
						color: map-deep-get($themeConfig, 'components', 'paging', 'numActive');
						background: map-deep-get($themeConfig, 'components', 'paging', 'numBG');
					}
				}
			}

			span {
				color: map-deep-get($themeConfig, 'components', 'paging', 'textColor');
			}

			& ~ .pagingNote {
				color: map-deep-get($themeConfig, 'components', 'paging', 'textColor');
			}
		}
		footer {
			@if map-deep-get($themeConfig, 'components', 'paging', 'borderColor') != 'none' {
				@include border(1, 1, 1, 1, solid, map-deep-get($themeConfig, 'components', 'paging', 'borderColor'));
			} @else {
				border: none;
			}

			background: map-deep-get($themeConfig, 'components', 'paging', 'bgColor');

			.pagingWrapper {
				ul {
					li {
						color: map-deep-get($themeConfig, 'components', 'paging', 'numText');

						i {
							color: map-deep-get($themeConfig, 'components', 'paging', 'textColor');
						}

						&:hover {
							color: map-deep-get($themeConfig, 'components', 'paging', 'numActive');
							background: map-deep-get($themeConfig, 'components', 'paging', 'numBG');
						}

						&.active {
							color: map-deep-get($themeConfig, 'components', 'paging', 'numActive');
							background: map-deep-get($themeConfig, 'components', 'paging', 'numBG');
						}
					}
				}

				span {
					color: map-deep-get($themeConfig, 'components', 'paging', 'textColor');
				}

				& ~ .pagingNote {
					color: map-deep-get($themeConfig, 'components', 'paging', 'textColor');
				}
			}
		}

		// Dashboard
		.dashboardWrapper {
			.chartWrapper {
				background: map-deep-get($themeConfig, 'dashboard', 'bgColor');

				text {
					fill: map-deep-get($themeConfig, 'dashboard', 'title') !important;
				}
			}

			.notificationWrapper {
				background: map-deep-get($themeConfig, 'dashboard', 'bgColor');

				.notificationList {
					border-color: map-deep-get($themeConfig, 'dashboard', 'bgColor');
					background: map-deep-get($themeConfig, 'contentArea', 'mainContentBG');

					i {
						color: map-deep-get($themeConfig, 'dashboard', 'subTitleLHS');
					}

					p {
						color: map-deep-get($themeConfig, 'dashboard', 'subTitleLHS');
					}
				}
				.commodityWrapper {
					background: none;
				}
			}

			.commodityWrapper {
				background: map-deep-get($themeConfig, 'dashboard', 'bgColor');
			}

			.dbCountWrapper {
				background: map-deep-get($themeConfig, 'dashboard', 'bgColor');

				h6 {
					color: map-deep-get($themeConfig, 'dashboard', 'title');
				}

				& > span {
					color: map-deep-get($themeConfig, 'dashboard', 'count');
				}

				div {
					color: map-deep-get($themeConfig, 'dashboard', 'title');

					& > span {
						color: map-deep-get($themeConfig, 'dashboard', 'count');
					}
				}

				&.bid {
					& > span {
						border-color: map-deep-get($themeConfig, 'bid', 'dark');
					}
				}

				&.quote {
					& > span {
						border-color: map-deep-get($themeConfig, 'quote', 'dark');
					}
				}

				&.default {
					& > span {
						border-color: map-deep-get($themeConfig, 'default', 'dark');
					}
				}
			}

			.dashboardSidebar {
				h3 {
					color: map-deep-get($themeConfig, 'dashboard', 'title');

					&.lineHR {
						&::after {
							// border-color: map-deep-get($themeConfig, 'dashboard', 'title');
						}
					}
				}

				.accordin-title {
					h6 {
						color: map-deep-get($themeConfig, 'dashboard', 'titleLHS');

						i {
							color: map-deep-get($themeConfig, 'dashboard', 'titleLHS');
						}

						& > span {
							color: map-deep-get($themeConfig, 'dashboard', 'subTitleLHS');
						}
					}
				}

				.collapse {
					&.show {
						.listGroupWrapper {
							a {
								color: map-deep-get($themeConfig, 'dashboard', 'titleLHS');
							}
						}
					}
				}
			}
		}

		//Commodity Code
		.commoditylist {
			.accordin-title {
				i {
					color: map-deep-get($themeConfig, 'components', 'accordin', 'icon');
				}
			}
		}
		.selectedcommodity {
			.deletecommodity {
				i {
					color: map-deep-get($themeConfig, 'components', 'accordin', 'icon');
				}
			}
		}

		// Form Group
		.checkControl,
		.radioControl {
			color: map-deep-get($themeConfig, 'formGroup', 'radioCheck', 'text');
		}

		.checkControl
		{
			input
			{
				&:disabled
				{
					&+ .checkmark {
						background-color: map-deep-get($themeConfig, 'formGroup', 'disabledCheck', 'background');
						cursor: default;
						border-color: map-deep-get($themeConfig, 'formGroup', 'inputGroup', 'border');
						&:after{
							border-color: map-deep-get($themeConfig, 'CommonThemeColor', 'commColor1');
						}
				}
				&:indeterminate
				{
					&~.indeterminate {
						background-color: map-deep-get($themeConfig, 'formGroup', 'disabledCheck', 'background');
						cursor: default;
						border-color: map-deep-get($themeConfig, 'formGroup', 'inputGroup', 'border');
						&:after{
							border-color: map-deep-get($themeConfig, 'CommonThemeColor', 'commColor1');
						}
				}
				}
			}
		}

		}
		.radioControl
		{
			input
			{
				&:disabled
				{
					&+ .checkmark {
						background-color: map-deep-get($themeConfig, 'formGroup', 'disabledCheck', 'background');
						cursor: default;
						border-color: map-deep-get($themeConfig, 'formGroup', 'inputGroup', 'border');
						&:after{
							border-color: map-deep-get($themeConfig, 'CommonThemeColor', 'commColor1');
						}
				}
				// &:indeterminate
				// {
				// 	&~.indeterminate {
				// 		background-color: map-deep-get($themeConfig, 'formGroup', 'disabledCheck', 'background');
				// 		cursor: default;
				// 		border-color: map-deep-get($themeConfig, 'formGroup', 'inputGroup', 'border');
				// 		&:after{
				// 			border-color: map-deep-get($themeConfig, 'CommonThemeColor', 'commColor1');
				// 		}
				// }
				// }
			}
		}
	}

		.selectWrapper,
		.comboWrapper {
			.dd-wrapper {
				.dd-header {
					background: transparent;

					span {
						color: map-deep-get($themeConfig, 'formGroup', 'inputGroup', 'iconColor');
					}

					.dd-header-title {
						color: map-deep-get($themeConfig, 'formGroup', 'inputGroup', 'labelColor');
					}
				}

				.dd-list {
					background: map-deep-get($themeConfig, 'formGroup', 'inputGroup', 'bgColor');
					border-color: map-deep-get($themeConfig, 'formGroup', 'inputGroup', 'border');
				}

				.dd-list-item {
					color: map-deep-get($themeConfig, 'formGroup', 'inputGroup', 'labelColor');

					.selected {
						background: map-deep-get($themeConfig, 'formGroup', 'inputGroup', 'ddHover');
						color: map-deep-get($themeConfig, 'formGroup', 'inputGroup', 'labelColor');
					}

					&:hover {
						background: map-deep-get($themeConfig, 'formGroup', 'inputGroup', 'ddHover');
						color: map-deep-get($themeConfig, 'formGroup', 'inputGroup', 'labelColor');
					}
				}
			}
		}

		.inputWrapper,
		.comboWrapper {
			div {
				input {
					background: map-deep-get($themeConfig, 'formGroup', 'inputGroup', 'bgColor');
					border-color: map-deep-get($themeConfig, 'formGroup', 'inputGroup', 'border');
					color: map-deep-get($themeConfig, 'formGroup', 'inputGroup', 'labelColor');
					&:disabled {
						background: map-deep-get($themeConfig, 'formGroup', 'inputGroup', 'disabled');
						color: map-deep-get($themeConfig, 'formGroup', 'inputGroup', 'disabledText');
					}
				}

				.react-date-picker {
					.react-date-picker__wrapper {
						background: map-deep-get($themeConfig, 'formGroup', 'inputGroup', 'bgColor');
						border-color: map-deep-get($themeConfig, 'formGroup', 'inputGroup', 'border');

						svg {
							stroke: map-deep-get($themeConfig, 'formGroup', 'inputGroup', 'iconColor');
						}
					}
				}

				label {
					color: map-deep-get($themeConfig, 'formGroup', 'inputGroup', 'labelColor');

					&:after {
						background: map-deep-get($themeConfig, 'formGroup', 'inputGroup', 'bgColor');
					}

					& + i {
						&[class*='mdi-'] {
							color: map-deep-get($themeConfig, 'formGroup', 'inputGroup', 'iconColor') !important;
						}
					}
				}
			}

			//options & error
			span {
				color: map-deep-get($themeConfig, 'formGroup', 'inputGroup', 'option');
			}

			textarea {
				background: map-deep-get($themeConfig, 'formGroup', 'inputGroup', 'bgColor');
				border-color: map-deep-get($themeConfig, 'formGroup', 'inputGroup', 'border');
				color: map-deep-get($themeConfig, 'formGroup', 'inputGroup', 'labelColor');
				&:focus {
					border-color: map-deep-get($themeConfig, 'formGroup', 'inputGroup', 'borderFocus');
				}
				&:disabled {
					background: map-deep-get($themeConfig, 'formGroup', 'inputGroup', 'disabled');
					color: map-deep-get($themeConfig, 'formGroup', 'inputGroup', 'disabledText');
				}
			}

			&.clearIcon {
				div {
					label {
						& + i {
							& + i {
								&[class*='mdi-'] {
									color: map-deep-get($themeConfig, 'formGroup', 'inputGroup', 'iconColor');
								}
							}
						}
					}
				}
			}
			&.errorWrapper {
				div {
					label {
						color: map-deep-get($themeConfig, 'formGroup', 'inputGroup', 'errorColor') !important;
					}
					input {
						border-color: map-deep-get($themeConfig, 'formGroup', 'inputGroup', 'errorColor') !important;
					}
				}
				.errorMsg {
					span {
						color: map-deep-get($themeConfig, 'formGroup', 'inputGroup', 'errorColor');
					}
				}
				textarea {
					border-color: map-deep-get($themeConfig, 'formGroup', 'inputGroup', 'errorColor') !important;
				}
			}
		}

		.datePickerWrapper {
			.DayPickerNavigation_button__default {
				.DayPickerNavigation_svg__horizontal {
					fill: map-deep-get($themeConfig, 'formGroup', 'inputGroup', 'iconColor');
				}
			}
			div {
				label {
					color: map-deep-get($themeConfig, 'formGroup', 'inputGroup', 'labelColor');

					&:after {
						background: map-deep-get($themeConfig, 'formGroup', 'inputGroup', 'bgColor');
					}
				}
				.DateRangePicker {
					.DateRangePickerInput__withBorder {
						border-color: map-deep-get($themeConfig, 'formGroup', 'inputGroup', 'border');
						background: map-deep-get($themeConfig, 'formGroup', 'inputGroup', 'bgColor');

						&:hover {
							border-color: map-deep-get($themeConfig, 'formGroup', 'inputGroup', 'borderFocus');
						}
						&:focus,
						&:focus-within {
							border-color: map-deep-get($themeConfig, 'formGroup', 'inputGroup', 'borderFocus');
						}
						.DateRangePickerInput_arrow {
							.DateRangePickerInput_arrow_svg {
								fill: map-deep-get($themeConfig, 'formGroup', 'inputGroup', 'iconColor');
							}
						}
					}
					.DateRangePickerInput {
						.DateRangePicker_picker {
							border-color: map-deep-get($themeConfig, 'formGroup', 'inputGroup', 'borderFocus');
							background: map-deep-get($themeConfig, 'formGroup', 'inputGroup', 'bgColor');
							.DayPicker__withBorder {
								background: map-deep-get($themeConfig, 'formGroup', 'inputGroup', 'bgColor');

								.CalendarMonth_table {
									background: map-deep-get($themeConfig, 'formGroup', 'datePicker', 'dayBG');
									tr {
										td {
											background: map-deep-get($themeConfig, 'formGroup', 'datePicker', 'dayBG');
											border: none;
											&.CalendarDay {
												background: map-deep-get($themeConfig, 'formGroup', 'datePicker', 'dayBG');
												color: map-deep-get($themeConfig, 'formGroup', 'datePicker', 'dayText');
												border: none;
												&:hover {
													background: map-deep-get($themeConfig, 'formGroup', 'datePicker', 'hoverBG');
													color: map-deep-get($themeConfig, 'formGroup', 'datePicker', 'hoverText');
												}
												&.CalendarDay__selected {
													background: map-deep-get($themeConfig, 'formGroup', 'datePicker', 'selectedBG');
													color: map-deep-get($themeConfig, 'formGroup', 'datePicker', 'selectedText');
												}
												&.CalendarDay__blocked_out_of_range {
													background: map-deep-get($themeConfig, 'formGroup', 'datePicker', 'disabledBG');
													color: map-deep-get($themeConfig, 'formGroup', 'datePicker', 'disabledText');
												}
												&.CalendarDay__selected_span {
													background: map-deep-get($themeConfig, 'formGroup', 'datePicker', 'hoverBG');
													color: map-deep-get($themeConfig, 'formGroup', 'datePicker', 'hoverText');
												}
												&.CalendarDay__hovered_span {
													background: map-deep-get($themeConfig, 'formGroup', 'datePicker', 'hoverBG');
													color: map-deep-get($themeConfig, 'formGroup', 'datePicker', 'hoverText');
												}
											}
										}
									}
								}
							}
						}
						.DayPicker_weekHeader {
							.DayPicker_weekHeader_ul {
								background: map-deep-get($themeConfig, 'formGroup', 'datePicker', 'headerBG');
								li {
									small {
										color: map-deep-get($themeConfig, 'formGroup', 'inputGroup', 'labelColor');
									}
								}
							}
						}
						.DateInput {
							input {
								color: map-deep-get($themeConfig, 'formGroup', 'inputGroup', 'labelColor');
							}
						}

						.DateRangePickerInput_calendarIcon {
							& > svg {
								fill: map-deep-get($themeConfig, 'formGroup', 'inputGroup', 'iconColor');
							}
						}
						.DateRangePickerInput_clearDates {
							i {
								color: map-deep-get($themeConfig, 'formGroup', 'inputGroup', 'iconColor');
							}
						}
					}
					.CalendarMonthGrid {
						background: map-deep-get($themeConfig, 'formGroup', 'inputGroup', 'bgColor');

						.CalendarMonth {
							background: map-deep-get($themeConfig, 'formGroup', 'inputGroup', 'bgColor');
							.CalendarDay__default {
								// background: map-deep-get($themeConfig, 'formGroup', 'datePicker', 'dayBG');
							}
							.CalendarMonth_caption {
								background: map-deep-get($themeConfig, 'formGroup', 'datePicker', 'bgColor');
								select {
									background: map-deep-get($themeConfig, 'formGroup', 'datePicker', 'bgColor');
									border-color: transparent !important;
									color: map-deep-get($themeConfig, 'formGroup', 'datePicker', 'textColor');
									@include sourceSansProRegular(16);
								}
							}
						}
					}
				}
				.SingleDatePicker {
					.SingleDatePickerInput {
						background: map-deep-get($themeConfig, 'formGroup', 'inputGroup', 'bgColor');

						.SingleDatePicker_picker__directionLeft {
							// border: none;
							border-radius: 0;
							top: calculateRem(46) !important;
							border-color: map-deep-get($themeConfig, 'formGroup', 'inputGroup', 'borderFocus');
						}
						.SingleDatePickerInput_calendarIcon {
							& > svg {
								fill: map-deep-get($themeConfig, 'formGroup', 'inputGroup', 'iconColor');
							}
						}
						.SingleDatePickerInput_clearDate {
							& > svg {
								fill: map-deep-get($themeConfig, 'formGroup', 'inputGroup', 'iconColor');
							}
						}
						.DayPicker_weekHeader {
							.DayPicker_weekHeader_ul {
								background: map-deep-get($themeConfig, 'formGroup', 'datePicker', 'headerBG');
								li {
									small {
										color: map-deep-get($themeConfig, 'formGroup', 'inputGroup', 'labelColor');
									}
								}
							}
						}
						.DateInput {
							.DateInput_input {
								background: map-deep-get($themeConfig, 'formGroup', 'inputGroup', 'bgColor');
								color: map-deep-get($themeConfig, 'formGroup', 'inputGroup', 'labelColor');
							}
						}
						&.SingleDatePickerInput__withBorder {
							border-color: map-deep-get($themeConfig, 'formGroup', 'inputGroup', 'border');
							&:hover {
								border-color: map-deep-get($themeConfig, 'formGroup', 'inputGroup', 'borderFocus');
							}
							&:focus,
							&:focus-within {
								border-color: map-deep-get($themeConfig, 'formGroup', 'inputGroup', 'borderFocus');
							}
						}
						.DayPicker__horizontal {
							&.DayPicker__withBorder {
								background: map-deep-get($themeConfig, 'formGroup', 'datePicker', 'bgColor');
								border-radius: 0;
								.CalendarMonth_table {
									background: map-deep-get($themeConfig, 'formGroup', 'datePicker', 'dayBG');
									tr {
										td {
											background: map-deep-get($themeConfig, 'formGroup', 'datePicker', 'dayBG');
											border: none;
											&.CalendarDay {
												background: map-deep-get($themeConfig, 'formGroup', 'datePicker', 'dayBG');
												color: map-deep-get($themeConfig, 'formGroup', 'datePicker', 'dayText');
												border: none;
												&:hover {
													background: map-deep-get($themeConfig, 'formGroup', 'datePicker', 'hoverBG');
												}
												&.CalendarDay__selected {
													background: map-deep-get($themeConfig, 'formGroup', 'datePicker', 'selectedBG');
													color: map-deep-get($themeConfig, 'formGroup', 'datePicker', 'selectedText');
												}
												&.CalendarDay__blocked_out_of_range {
													background: map-deep-get($themeConfig, 'formGroup', 'datePicker', 'disabledBG');
													color: map-deep-get($themeConfig, 'formGroup', 'datePicker', 'disabledText');
												}
											}
										}
									}
								}
								.CalendarMonthGrid {
									background: map-deep-get($themeConfig, 'formGroup', 'datePicker', 'bgColor');
									.CalendarMonth {
										background: map-deep-get($themeConfig, 'formGroup', 'datePicker', 'bgColor');
										.CalendarMonth_caption {
											background: map-deep-get($themeConfig, 'formGroup', 'datePicker', 'bgColor');
											select {
												background: map-deep-get($themeConfig, 'formGroup', 'datePicker', 'bgColor');
												border-color: transparent !important;
												color: map-deep-get($themeConfig, 'formGroup', 'datePicker', 'textColor');
												@include sourceSansProRegular(16);
											}
										}
									}
								}
							}
						}
					}
				}
			}
			&.errorWrapper {
				div {
					label {
						color: map-deep-get($themeConfig, 'formGroup', 'inputGroup', 'errorColor') !important;
					}
					.DateRangePicker {
						.DateRangePickerInput {
							border-color: map-deep-get($themeConfig, 'formGroup', 'inputGroup', 'errorColor');
						}
					}
					.SingleDatePicker {
						.SingleDatePickerInput {
							&.SingleDatePickerInput__withBorder {
								border-color: map-deep-get($themeConfig, 'formGroup', 'inputGroup', 'errorColor');
								input {
									border: none !important;
								}
							}
						}
					}
				}
				.errorMsg {
					span {
						color: map-deep-get($themeConfig, 'formGroup', 'inputGroup', 'errorColor');
					}
				}
			}
			span {
				color: map-deep-get($themeConfig, 'formGroup', 'inputGroup', 'option');
			}
		}

		.timePickerWrapper {
			& > label {
				color: map-deep-get($themeConfig, 'formGroup', 'inputGroup', 'labelColor');

				&:after {
					background: map-deep-get($themeConfig, 'formGroup', 'inputGroup', 'bgColor');
				}
			}
			& > div {
				border-color: map-deep-get($themeConfig, 'formGroup', 'inputGroup', 'border');
			}
		}

		.selectWrapper {
			& > div {
				&:first-child {
					background: map-deep-get($themeConfig, 'formGroup', 'inputGroup', 'bgColor');
					border-color: map-deep-get($themeConfig, 'formGroup', 'inputGroup', 'border');

					label {
						color: map-deep-get($themeConfig, 'formGroup', 'inputGroup', 'labelColor');

						&:after {
							background: map-deep-get($themeConfig, 'formGroup', 'inputGroup', 'bgColor');
						}
					}
					ul {
						input {
							background: transparent;
							color: map-deep-get($themeConfig, 'formGroup', 'inputGroup', 'labelColor');
						}
					}
				}
			}

			& ~ span {
				color: map-deep-get($themeConfig, 'formGroup', 'inputGroup', 'iconColor');
			}
		}

		.react-select {
			&.errorWrapper {
				div {
					&:first-child {
						border-color: map-deep-get($themeConfig, 'formGroup', 'inputGroup', 'errorColor');
						label {
							color: map-deep-get($themeConfig, 'formGroup', 'inputGroup', 'errorColor') !important;
						}
					}
				}
				.errorMsg {
					span {
						color: map-deep-get($themeConfig, 'formGroup', 'inputGroup', 'errorColor') !important;
					}
				}
			}
			&.disable {
				& > div {
					&:first-child {
						background: map-deep-get($themeConfig, 'formGroup', 'inputGroup', 'disabled');
						color: map-deep-get($themeConfig, 'formGroup', 'inputGroup', 'disabledText');
						// border: none;
					}
					&:hover {
						border-color: map-deep-get($themeConfig, 'formGroup', 'inputGroup', 'border');
					}
				}
				[class*='css-'][class$='-control'] {
					background: none;
					[class*='css-'][class$='-IndicatorsContainer'] {
						[class*='css-'][class$='-indicatorContainer'] {
							svg {
								fill: map-deep-get($themeConfig, 'formGroup', 'inputGroup', 'disabledText');
							}
						}
					}
					[class*='css-'][class$='-singleValue'] {
						color: map-deep-get($themeConfig, 'formGroup', 'inputGroup', 'disabledText');
					}
				}
			}
			& > div {
				&:first-child {
					background: map-deep-get($themeConfig, 'formGroup', 'inputGroup', 'bgColor');
					border-color: map-deep-get($themeConfig, 'formGroup', 'inputGroup', 'border');

					label {
						color: map-deep-get($themeConfig, 'formGroup', 'inputGroup', 'labelColor');

						&:after {
							background: map-deep-get($themeConfig, 'formGroup', 'inputGroup', 'bgColor');
						}
					}
				}
				&:focus,
				&:focus-within {
					border-color: map-deep-get($themeConfig, 'formGroup', 'inputGroup', 'borderFocus');
				}

				[class*='css'][class$='control'] {
					background: map-deep-get($themeConfig, 'formGroup', 'inputGroup', 'bgColor');
					// [class*='css-'][class$='-IndicatorsContainer'] {
					[class*='css'][class$='indicatorContainer'] {
						cursor: pointer;
						color: map-deep-get($themeConfig, 'formGroup', 'inputGroup', 'iconColor');

						[class*='css'][class$='Svg'] {
							fill: map-deep-get($themeConfig, 'formGroup', 'inputGroup', 'iconColor');
						}
					}
					// }
				}

				[class*='css-'][class$='-singleValue'] {
					color: map-deep-get($themeConfig, 'formGroup', 'inputGroup', 'labelColor');
				}
				.custom-react-select {
					[class*='css-'][class$='-menu'] {
						border-radius: 0 !important;
						background: map-deep-get($themeConfig, 'formGroup', 'inputGroup', 'bgColor') !important;
						// display: block !important;
						& > [class*='css-'][class$='-MenuList']
						{
							position: absolute;
						}
						& > [class*='css-'][class$='-MenuList'],
						& > [class*='css-'] {
							// background: map-deep-get($themeConfig, 'formGroup', 'inputGroup', 'bgColor');
							width: calc(100% - -0.125rem);
							padding-top: 0.625rem;
							padding-bottom: 0.625rem;
							margin-bottom: 0;
							margin-left: -0.0625rem;
							max-height: calc(100vh - 50vh);
							overflow: auto;
							border-radius: 0 !important;
							box-shadow: none;
							background: map-deep-get($themeConfig, 'formGroup', 'inputGroup', 'bgColor') !important;
							@include border(1, 1, 1, 1, solid, $gray50);
							@include border(1, 1, 1, 1, solid, map-deep-get($themeConfig, 'formGroup', 'inputGroup', 'border'));
							& > [class*='css-'][class$='-option'] {
								padding-left: calculateRem(28);
								color: map-deep-get($themeConfig, 'formGroup', 'inputGroup', 'labelColor');
							
								&:hover {
									color: map-deep-get($themeConfig, 'formGroup', 'inputGroup', 'labelColor');
									cursor: pointer;
								}
								&:active {
									background: transparent;
									color: map-deep-get($themeConfig, 'formGroup', 'inputGroup', 'labelColor');
								}
								& > span {
									color: $teal !important;
									font-size: calculateRem(19);
									position: absolute;
									top: auto;
									left: calculateRem(0);
									margin-top: calculateRem(-5);
								}
							}
						}
					}
				}
			}
		}

		// upload button
		.uploadWrapper {
			button {
				background: map-deep-get($themeConfig, 'formGroup', 'bttnSecondary', 'normalBG');
				border-color: map-deep-get($themeConfig, 'formGroup', 'bttnSecondary', 'border');
				color: map-deep-get($themeConfig, 'formGroup', 'bttnSecondary', 'text');
				cursor: pointer;
				& + input {
					cursor: pointer;
				}
				&:disabled {
					background: map-deep-get($themeConfig, 'formGroup', 'bttnSecondary', 'disable', 'bg');
					color: map-deep-get($themeConfig, 'formGroup', 'bttnSecondary', 'disable', 'text');
					border-color: map-deep-get($themeConfig, 'formGroup', 'bttnSecondary', 'disable', 'border');
					cursor: not-allowed;
					& + input {
						cursor: not-allowed;
					}
				}
			}
			&.errorWrapper {
				button {
					border-color: map-deep-get($themeConfig, 'formGroup', 'inputGroup', 'errorColor');
					color: map-deep-get($themeConfig, 'formGroup', 'inputGroup', 'errorColor');
				}
				.errorMsg {
					span {
						color: map-deep-get($themeConfig, 'formGroup', 'inputGroup', 'errorColor');
					}
				}
			}
		}

		// Button
		.bttn-primary {
			background: map-deep-get($themeConfig, 'formGroup', 'bttnprimary', 'normalBG');
			border-color: map-deep-get($themeConfig, 'formGroup', 'bttnprimary', 'border');
			color: map-deep-get($themeConfig, 'formGroup', 'bttnprimary', 'text');

			&:hover {
				background: map-deep-get($themeConfig, 'formGroup', 'bttnprimary', 'hoverBG');
				color: map-deep-get($themeConfig, 'formGroup', 'bttnprimary', 'text');
			}

			&:active {
				background: map-deep-get($themeConfig, 'formGroup', 'bttnprimary', 'activeBG');
			}

			&:disabled {
				background: map-deep-get($themeConfig, 'formGroup', 'bttnprimary', 'disableBG');
				color: map-deep-get($themeConfig, 'formGroup', 'bttnprimary', 'text');
			}
		}

		.bttn-secondary {
			background: map-deep-get($themeConfig, 'formGroup', 'bttnSecondary', 'normalBG');
			border-color: map-deep-get($themeConfig, 'formGroup', 'bttnSecondary', 'border');
			color: map-deep-get($themeConfig, 'formGroup', 'bttnSecondary', 'text');

			&:hover {
				background: map-deep-get($themeConfig, 'formGroup', 'bttnSecondary', 'hoverBG');
			}

			&:disabled {
				background: map-deep-get($themeConfig, 'formGroup', 'bttnSecondary', 'disableBG');
				color: map-deep-get($themeConfig, 'formGroup', 'bttnSecondary', 'text');
				border-color: map-deep-get($themeConfig, 'formGroup', 'bttnSecondary', 'border');
			}
		}

		// Page Footer
		footer {
			&.footerWrapper {
				background: map-deep-get($themeConfig, 'footer', 'bgColor');
				color: map-deep-get($themeConfig, 'footer', 'textColor');

				a {
					color: map-deep-get($themeConfig, 'footer', 'linkColor') !important;

					&:hover {
						color: map-deep-get($themeConfig, 'footer', 'linkColor') !important;
					}
				}
			}
		}

		// Similar Bids percentage
		.similarBidsTab {
			.listGroupWrapper {
				ul {
					.list-inline-item {
						strong {
							color: map-deep-get($themeConfig, 'contentArea', 'listItems', 'text');
						}
						div {
							background: map-deep-get($themeConfig, 'headerNav', 'activeBG');
							color: map-deep-get($themeConfig, 'headerNav', 'activeText');
						}
					}
				}
			}
		}

		// envelope e-bid
		.envelopeWrapper {
			.envelopeTxt {
				h4 {
					color: $o-black;
				}
			}
		}

		// Braintree
		@if ($theme-name == 'dark_DS') {
			.braintreeWrapper {
				.braintree-upper-container {
					.braintree-sheet {
						background: $white400;
					}
				}
			}
		}

		// Coming Soon
		.comingSoonWrapper {
			ol {
				li {
					& > span {
						color: map-deep-get($themeConfig, 'body', 'textColor');
					}
				}
			}
		}

		.btnDropDown {
			background: transparent !important;
			border-color: map-deep-get($themeConfig, 'formGroup', 'bttnSecondary', 'border') !important;
			color: map-deep-get($themeConfig, 'formGroup', 'bttnSecondary', 'text') !important;
			text-align: center;
			outline: none;
			&:hover {
				background: map-deep-get($themeConfig, 'formGroup', 'bttnSecondary', 'hoverBG') !important;
				outline: none !important;
			}

			&:active {
				outline: none !important;
			}

			&:focus {
				outline: none !important;
			}

			& ~ .dropdown-menu {
				background: map-deep-get($themeConfig, 'formGroup', 'inputGroup', 'bgColor');
				.dropdown-item {
					color: map-deep-get($themeConfig, 'formGroup', 'bttnSecondary', 'text');
					cursor: pointer;
					&:hover {
						background: map-deep-get($themeConfig, 'formGroup', 'inputGroup', 'bgColor');
					}
				}
			}
		}

		.searchToggle {
			background: transparent
				linear-gradient(
					map-deep-get($themeConfig, 'contentArea', 'sideBarAdvSrch', 'expColpBG1'),
					map-deep-get($themeConfig, 'contentArea', 'sideBarAdvSrch', 'expColpBG2')
				);
			h5 {
				&::after {
					background: map-deep-get($themeConfig, 'contentArea', 'sideBarAdvSrch', 'textLine');
				}
				& > label {
					background: map-deep-get($themeConfig, 'contentArea', 'sideBarAdvSrch', 'textBG');
					color: map-deep-get($themeConfig, 'contentArea', 'sideBarAdvSrch', 'text');
				}
			}
			span {
				background-color: $purple;
			}
			i {
			}
			&.searchExp {
				h5 {
					& > label {
						background: map-deep-get($themeConfig, 'contentArea', 'sideBarAdvSrch', 'textBG');
					}
					i {
					}
				}
			}
		}

		button.scrollButton {
			border: 0;
			//			background-color: map-deep-get($themeConfig, 'CommonThemeColor', 'commColor2');
			cursor: pointer;
			background: transparent;
			i {
				color: map-deep-get($themeConfig, 'CommonThemeColor', 'commColor1');
			}
			&:hover {
				i {
					color: map-deep-get($themeConfig, 'CommonThemeColor', 'commHover1');
				}
			}
		}
		.highlight{
			background-color: $light-yellow;
			color: $o-black;
			position: relative;
			left: calculateRem(-3);
			padding-left: calculateRem(3);
			padding-right: calculateRem(3);
			border-radius: calculateRem(3);// 1em 1em 0.25em;
		}

		#DemandStar_-_Logo-Loader {
			path{
				fill: transparent;
				&.highlight {
					fill: map-deep-get($themeConfig, 'headerNav', 'logoColor');
				}
			}
		}

	// Subscription
		.subscriptionWrapper
		{
			.bg-coolgray50
			{
				color:$o-black;
				.staticLink
				{
					color: $teal!important;
				}
			}
		.subProductWrapper
		{
			.subStateList
    		{
        		&.selectedProduct{
            		&>div{
							background: $teal;
					.countyName {
						color: $white50;
						}
            		}
				}
				&>div{
					background: map-deep-get($themeConfig, 'default', 'medium');
					.countyName {
						color: map-deep-get($themeConfig, 'default', 'text');
						}
            		}
			}
			.countyCollapse
			{
				.card{
					background: none;
				}
			}
		}
	}

   .cardmain {
     text-align: center;
    }

	.iconsDashboard{
		height: 4rem;	
	}
	.card-content{
		height: 10rem;
	}
   
   .card-content{
     background-color:$o-white200;
     padding: 0.938rem;
     font-weight: 600;
   }
   
   .card-content:hover {
     cursor: pointer;
   }
	}
}

// @include themable(dark_DS, #366F3C);
