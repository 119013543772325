@import url('https://fonts.googleapis.com/css?family=Fira+Sans:300,400,500,600,700');
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400i,600,700');
@import url('https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');
@import url('https://cdn.jsdelivr.net/npm/@mdi/font@5.0.45/css/materialdesignicons.min.css');

@import './_color_utilities.scss';

/*Fonts*/
/*Fonts declar*/
$o-oswald: 'Oswald', sans-serif;
$o-merriweather: 'Merriweather', serif;
$o-firaSans: 'Fira Sans', sans-serif;
$o-sourceSansPro: 'Source Sans Pro', sans-serif;
$o-light: 300;
$o-regular: 400;
$o-medium: 500;
$o-semiBold: 600;
$o-bold: 700;
$o-normal: normal;
$o-italic: italic;
/*Fonts Family*/
$oswald: $o-oswald;
$merriweather: $o-merriweather;
$firaSans: $o-firaSans;
$sourceSansPro: $o-sourceSansPro;

/*Fonts Weight*/
$light: $o-light;
$regular: $o-regular;
$medium: $o-medium;
$semiBold: $o-semiBold;
$bold: $o-bold;
/*Fonts Style*/
$normal: $o-normal;
$italic: $o-italic;



