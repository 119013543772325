// Change default bootstrap styles
$container-max-widths: (
  //md: 768px,
  // lg: 960px,
  xl: 1600px //xl: 1140px
) !default;
$grid-gutter-width: 32px !default;
// $mdi-font-path: '~@mdi/font/fonts';

@import '~bootstrap/scss/bootstrap.scss';
// @import '~@mdi/font/scss/materialdesignicons.scss';
@import './utils/variables.scss';
@import './utils/mixins.scss';
@import './utils/helper.scss';
@import './ie.scss';
@import './print.scss';
@import './device.scss';
@import './subscription.scss';
@import './components/upsell_card.scss';
@import './components/forms/index';
@import './components/purchase_plan_card.scss';

:root {
  font-size: 16px;
}

html {
  font-size: 16px !important;
  height: auto !important;
  width: auto !important;
}

body {
  // font-size: 16px;
  overflow-x: hidden;
  background: $o-white100;
  @include sourceSansProRegular(16);
  color: $black;

  .w-30 {
    width: 30% !important;
  }

  .w-60 {
    width: 60% !important;
  }
}

a {
  @include sourceSansProRegular(16);
  color: $teal;

  &:hover {
    text-decoration: none;
    color: $teal;
  }
}

small {
  @include sourceSansProRegular(12);
  display: block;

  a {
    @include sourceSansProRegular(12);
  }
}

.margin-top-2rem {
  margin-top: 2rem;
}

.table-borderless {
  th,
  td {
    border: none;
  }
}

.errorTitle {
  h1 {
    @include firaSansBold(16);
    text-align: center;
    color: #fff;
    // margin-top: 1em;
    @include margin(1, 0, 0, 0);
  }

  p {
    @include sourceSansProRegular(16);
    text-align: center;
    color: #fff;
    margin-top: 0.2em;
  }
}

.colWrapper {
  background: $white;
  @include border(1, 1, 1, 1, solid, $lightGray);
  @include padding(58, 60, 80, 60);
  height: 100%;
  min-height: calc(100vh - 189px);
  position: relative;

  & > h4 {
    margin-top: 0;

    &:first-child {
      margin-top: 0;
    }
  }

  h4 {
    // margin-top: calculateRem(42);
    overflow: hidden;

    &:after {
      content: '';
      display: inline-block;
      width: 100%;
      height: 100%;
      margin-right: -100%;
      border-bottom: 2px solid $lightGray;
      position: relative;
      top: -3px;
      left: 5px;
    }

    span {
      color: $teal;
      cursor: pointer;
      @include sourceSansProRegular(14);
    }

    a,
    span {
      float: right;
      position: relative;
      z-index: 1;
      background: $white;
      padding-left: 10px;
      font-size: 14px;
      margin-top: 3px;
    }
  }
  .noBorderBottomHeader{
    &:after {
    border-bottom: none ;
  }
}
}

.sideBarSpacing {
  background: $white;
  @include border(1, 1, 1, 1, solid, $lightGray);
  @include padding(58, 32, 80, 32);
  height: 100%;

  & > h4 {
    margin-top: 0;

    &:first-child {
      margin-top: 0;
    }
  }

  h4 {
    margin-top: calculateRem(42);
    overflow: hidden;

    &:after {
      content: '';
      display: inline-block;
      width: 100%;
      height: 100%;
      margin-right: -100%;
      border-bottom: 2px solid $lightGray;
      position: relative;
      top: -3px;
      left: 5px;
    }

    span {
      color: $teal;
      cursor: pointer;
      @include sourceSansProRegular(14);
    }

    a,
    span {
      float: right;
      position: relative;
      z-index: 1;
      background: $white;
      padding-left: 10px;
      font-size: 14px;
      margin-top: 3px;
    }
  }
}

.innerColIndent {
  @include padding(0, 32, 0, 32);
  // th,
  // td {
  // 	@include padding(8, null, 8, null);
  // 	// @include margin(null, null, 16, null);
  // }
  & > .tableData {
    // margin-left: calculateRem(-8);
  }
  .MandatoryOptRadioBtn{
    padding-top: calculateRem(17) ;
    padding-right: calculateRem(15);
  }
}

table {
  tr {
    &.active {
      @include border(0, 0, 0, 5, solid, $purple);

      &::after {
        content: '';
        width: 0;
        height: 0;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-right: 10px solid $purple;
        position: absolute;
        margin-top: 16px;
        right: -16px;
      }

      // .left {
      // 	width: 0;
      // 	height: 0;
      // 	border-style: solid;
      // 	border-width: 16px 17px 16px 0;
      // 	border-color: transparent #007bff transparent transparent;
      // }
    }
  }

  td {
    vertical-align: top;
    .selectWrapper {
      margin-bottom: 0;

      &.errorWrapper {
        & > div:first-child {
          border-color: $red;
        }
      }

      label {
        padding-left: 0 !important;
        padding-right: 0 !important;
      }

      .dd-wrapper {
        padding-bottom: calculateRem(1);
        padding-top: calculateRem(2);
        .dd-list {
          top: calculateRem(32);
          .dd-list-item {
            span {
              @include margin(0, 0, 0, 4);
            }
          }
        }
      }

      .errorMsg {
        min-height: auto;
      }
    }

    .tableTextarea {
      div {
        textarea {
          height: calculateRem(64);
          @include padding(6, 16, 6, 16);
        }
      }
    }
  }
}

.scrollFilter {
  @include padding(20, 30, 20, 30);
  background: $o-white200;
}

.scrollResult {
  overflow: hidden;
  @include padding(20, 10, 20, 20);
}

.headerFilter {
  @include margin(0, 0, 0, 0);
  @include border(0, 0, 1, 0, solid, $lightGray);
  min-height: calculateRem(64);

  h2 {
    margin-bottom: 0px;
  }

  b {
    @include firaSansSemiBold(14);
  }

  .inputWrapper {
    div {
      label {
        display: none;
      }
      input {
        @include padding(4, 16, 4, 16);
        height: calculateRem(32);
      }
    }
    .errorMsg {
      display: none;
    }
  }

  .selectWrapper {
    float: left;
    width: calculateRem(160);
    @include margin(0, 0, 0, 15);

    // margin-left: 15px;
    // margin-top: 10px;
    & > div {
      &:first-child {
        // border: none;
      }

      label {
        display: none;
      }

      .dd-wrapper {
        padding: 0;

        .dd-list {
          top: calculateRem(25);
        }

        .dd-header {
          span {
            margin-top: 0;
            font-family: 'FontAwesome' !important;
            font-weight: normal;
          }
        }

        .dd-header-title {
          @include firaSansSemiBold(14);
          margin-left: 0;
        }
      }
    }

    .errorMsg {
      min-height: auto;
      padding: 0;
    }
  }

  .datePickerWrapper {
    width: calculateRem(580);
    span {
      color: $placeholdergray;
      @include sourceSansProRegular(14);
      cursor: default;
    }
  }

  .inputWrapper {
    float: left;
    width: 30%;
    margin-bottom: 0;
    margin-top: calculateRem(20);
  }

  span {
    color: $darkTeal;
    // margin-top: 25px;
    @include margin(0, 0, 0, 0);
    cursor: pointer;
  }

  .sortWrapper {
    float: right;
    min-width: calculateRem(210);
    // cursor: pointer;

    .selectWrapper {
      margin-left: 0;
      cursor: pointer;

      .dd-wrapper {
        .dd-list {
          .dd-list-item {
            border: none;
          }
        }
      }
    }

    & > span {
      margin-top: calculateRem(0);
      position: relative;
      top: calculateRem(4);
      cursor: pointer;
    }
  }
}

.listWrapper {
  max-height: calc(100vh - 195px);
  overflow: auto;
}

.listWrapperPaging {
  max-height: calc(100vh - 255px);
  overflow: auto;
}

.listGroupWrapper {
  // padding: 15px;
  @include padding(16, 16, 16, 16);
  @include border(0, 0, 1, 0, solid, $lightGray);
  // border-bottom: 1px solid $lightGray;
  background: $white;

  // &:first-child {
  // 	// border-top: 1px solid $gray;
  // }
  &.border-top {
    // border-top: 2px solid $gray !important;
    @include border(2, 0, 0, 0, solid, $gray);
  }

  h5 {
    // @include sourceSansProSemiBold(16,!important);
    color: $black;
    display: flex;
    margin-bottom: 0;

    a {
      @include sourceSansProSemiBold(18);
      line-height: calculateRem(24);
      color: $darkTeal;
      text-decoration: none;
      flex: 1;

      &:hover {
        text-decoration: none;
      }
    }

    span {
      // float: right;
      min-width: calculateRem(114);
      max-width: calculateRem(200);
      height: calculateRem(22);
      @include firaSansSemiBold(12);
      @include padding(null, 12, null, 12);
      text-align: center;
      margin-right: calculateRem(24);
      margin-left: calculateRem(24);
      background: $light-yellow;
      @include borderRadius(10, 10, 10, 10);
      line-height: calculateRem(24);

      &.draft {
        background: $red;
        color: $o-white50;
      }
      &.cancelledbg {
        background: $o-white400;
        color: $o-gray550;
      }
      &.awardedbg {
        background: $dark-green;
        color: $o-white50;
      }
    }

    i {
      width: 20px;
      color: $teal;
      cursor: pointer;

      &.upcomingAlert {
        line-height: 1.5rem;
      }
    }
  }

  p {
    @include sourceSansProRegular(14);
    margin-bottom: calculateRem(0) !important;
    line-height: calculateRem(20);
    color: $placeholdergray;
  }

  ul {
    margin-bottom: 0;
    display: flex;

    li {
      @include sourceSansProRegular(16);
      line-height: calculateRem(24);
      color: $placeholdergray;
      flex: 1;

      &:first-child {
        margin-left: 0;
        flex-basis: 7.5rem;
      }

      &:nth-child(2) {
        flex-basis: 3rem;
      }

      &:nth-child(3) {
        flex-basis: 2rem;
      }

      &:nth-child(4) {
        flex-basis: 1rem;
      }

      i {
        @include firaSansSemiBold(16);
        line-height: calculateRem(32);
        font-style: normal;
        color: $black;
      }
    }
  }
}

.accoWrapper {
  @include firaSansSemiBold(14);
  color: $black;
  cursor: pointer;
  display: block;
  @include padding(8, 0, 8, 0);
  @include border(0, 0, 1, 0, solid, $gray);

  span {
    @include border(1, 1, 1, 1, solid, $teal);
    border-radius: 50%;
    @include padding(2, 3, 0, 3);
    margin-right: calculateRem(6);

    &:before {
      color: $teal;
    }
  }
}

.card {
  border: none;

  .card-body {
    padding: 0;
  }
}

/*Login*/
.loginWrapper {
  height: 100vh;
  @include sourceSansProRegular(16);

  & > div {
    height: 100vh;

    & > [class*='col-'] {
      background: $white;
      @include padding(25, 50, 25, 50);
      @include borderRadius(4, 4, 4, 4);
      @include border(1, 1, 1, 1, solid, $teal);

      & > span {
        & > svg {
          cursor: pointer;
          width: calculateRem(200);
          margin: auto;
          margin-bottom: calculateRem(20);
          display: block;
          path {
            fill: $teal;
          }
        }
      }

      .error {
        color: red;
      }
    }
  }

  .action-buttons {
    .col {
      margin-top: 0.5rem;
    }
    .bttn-secondary {
      width: calculateRem(175);
      max-width: calculateRem(175);
      text-align: center;
    }
  }
}

/*Tab css*/
.nav-tabs {
  border: none;
  padding-left: calculateRem(24) !important;

  .nav-item {
    margin-bottom: calculateRem(-4) !important;

    .nav-link {
      @include border(1, 0, 4, 1, solid, $gray);
      @include borderRadius(1, 1, 0, 0);
      @include boxShadow(0, 0, 0, $gray);
      background: $o-white200;
      height: calculateRem(52);
      padding-top: calculateRem(12);
      cursor: pointer;
      @include sourceSansProRegular(18);
      line-height: calculateRem(24);

      &.active {
        @include border(4, 0, 0, 0, solid, $lightGray);
        border-top-color: $teal !important;
        background: $white;
        padding-top: calculateRem(9);
      }
    }
  }
}

.tab-content {
  background: $white;
  @include border(4, 1, 1, 1, solid, $lightGray);
  @include padding(58, 60, 80, 60);
  min-height: calc(100vh - 10.4375rem);

  & > .active {
    ul {
      li {
        margin-bottom: 10px;
      }
    }
  }

  h4 {
    overflow: hidden;

    &:after {
      content: '';
      display: inline-block;
      width: 100%;
      height: 100%;
      margin-right: -100%;
      border-bottom: 2px solid $lightGray;
      position: relative;
      top: -3px;
      left: 5px;
    }

    span {
      color: $teal;
      cursor: pointer;
      @include sourceSansProRegular(14);
    }

    a,
    span {
      float: right;
      position: relative;
      z-index: 1;
      background: $white;
      padding-left: 10px;
      font-size: 14px;
      margin-top: 3px;
    }
  }
  table {
    td {
      li {
        .successTick,
        .notSubmit,
        .noneDot,
        .manual {
          line-height: calculateRem(14);
          margin-bottom: 0;
        }
        .document-tabulation {
          margin-right: 30px;
          display: table-caption;
        }
      }
    }
  }
}

/*Detail View*/
.detailViewWrapper {
  min-height: calc(100vh - 11.4375rem);

  h3 {
    @include firaSansSemiBold(24);
    margin-bottom: 0;

    &.d-flex {
      span {
        flex: 1;
        line-height: 120% !important;
        min-height: calculateRem(55);
        padding-top: calculateRem(13);
        padding-bottom: calculateRem(10);
      }
    }

    & + div {
      span {
        @include firaSansBold(13);
      }
    }

    i {
      max-height: calculateRem(28);
      margin-top: calculateRem(-43);
    }
  }

  h4 {
    margin-top: calculateRem(35);

    & + hr {
      margin: 0;
      margin-bottom: calculateRem(10);
    }
  }

  p {
    margin-bottom: calculateRem(8);
  }

  table {
    td {
      h6 {
        display: block;
        margin-bottom: 0;
      }

      span {
        @include padding(2, 0, 4, 0);

        small {
          @include firaSansSemiBold(14);
        }
      }
    }
  }

  .listGroupWrapper {
    &:first-child {
      padding-top: 0;
    }

    @include padding(15, 0, 15, 0);

    &:first-child {
      padding-top: 0;
    }

    h5 {
      a {
        i {
          display: none;
        }
      }
    }

    ul {
      display: inline-block;

      li {
        margin-right: calculateRem(25);
        margin-bottom: 0;
      }
    }
  }

  .responsecontent {
    .leftBorder {
      @include border(0, 0, 0, 5, solid, $purple);
      padding-left: calculateRem(16);
    }
    .setFullHeight {
      height: 100%;
    }
  }

  .sideBarSpacing {
    margin-top: calculateRem(48);
    height: calc(100% - 48px);

    .similarBids {
      &.listGroupWrapper {
        @include padding(8, 0, 8, 0);
      }

      background: none;

      h5 {
        margin-bottom: calculateRem(0);
      }
    }

    // Bids Count
    .bidsCounts {
      div {
        width: 50%;
      }

      h2 {
        margin-bottom: 0;
      }
    }
  }
}

.similarBidsTab {
  .listGroupWrapper {
    padding-top: calculateRem(16) !important;
    @include padding(16, 16, 16, 16);

    ul {
      display: flex;
      .list-inline-item {
        strong {
          float: right;
          position: relative;
          top: calculateRem(5);
          right: calculateRem(16);
          color: $o-black;
        }
        div {
          height: calculateRem(34);
          width: calculateRem(34);
          line-height: calculateRem(33.5);
          text-align: center;
          float: right;
          color: $o-black;
          @include sourceSansProBold(12);
          background: $light-teal;
        }
      }
    }
  }
}

.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.96);
  z-index: 11111;
}

.progressbox {
  position: fixed;
  top: 40%;
  padding: 3px;
  left: 44%;
  right: 30%;
  border-radius: calculateRem(10);
  color: #7f7f7f;
  z-index: 101;
}

.progressbox img {
  width: calculateRem(38);
  height: calculateRem(38);
}

.progressbox > div:first-child {
  display: inline-block;
  width: 20%;
  float: left;
  padding-left: calculateRem(15);
  padding-top: calculateRem(10);
  margin-top: calculateRem(6);
}

/*Wizard or Stepper*/
.wizardWrapper {
  display: flex;
  width: 100%;

  .wizardItem {
    &:last-child {
      .stepsline {
        display: none;

        &:after {
          display: none;
        }
      }
    }

    position: relative;
    display: inline-block;
    flex: 1;

    .stepsline {
      width: 100%;
      position: absolute;
      left: 0;
      top: calculateRem(-7);
      margin-left: calculateRem(38);
      padding: 0;
      vertical-align: top;

      &:after {
        content: '';
        display: inline-block;
        height: calculateRem(2);
        background: $gray;
        width: calc(100% - 13px);
        margin-left: 14px;
        border-radius: 1px;
      }
    }

    .stepsCircle {
      background: none;
      @include border(2, 2, 2, 2, solid, $gray);
      width: calculateRem(16);
      height: calculateRem(16);
      border-radius: 50%;
      text-align: center;
      padding: 0;
      display: inline-block;
      margin-left: calculateRem(37);
      line-height: calculateRem(13);
      font-size: calculateRem(10);
    }

    .wizardContent {
      display: block;
      width: calculateRem(90);
      text-align: center;
      padding-left: calculateRem(70);
      @include firaSansBold(13);

      .staticLink {
        @include firaSansBold(13);
        color: $darkTeal;

        &:hover {
          color: $teal;
        }
      }
    }

    &.current {
      .stepsCircle {
        background: $purple;
        @include border(2, 2, 2, 2, solid, $purple);

        &:before {
          color: $white;
          content: '\F04CE';
          font-family: 'Material Design Icons';
          position: relative;
          top: calculateRem(-0.7);
          left: calculateRem(0.5);
          font-size: calculateRem(12);
        }
      }
    }

    &.current-forward-nav {
      .stepsline {
        top: calculateRem(-6);

        &:after {
          height: calculateRem(3);
          background: $purple;
        }
      }

      .stepsCircle {
        background: $purple;
        @include border(2, 2, 2, 2, solid, $purple);

        &:before {
          color: $white;
          content: '\F04CE';
          font-family: 'Material Design Icons';
          font-weight: normal;
          position: relative;
          top: calculateRem(-0.7);
          left: calculateRem(0.5);
          font-size: calculateRem(12);
        }
      }
    }

    &.completed {
      .stepsline {
        top: calculateRem(-6);

        &:after {
          height: calculateRem(3);
          background: $purple;
        }
      }

      .stepsCircle {
        background: $purple;
        @include border(2, 2, 2, 2, solid, transparent);
        position: relative;
        top: calculateRem(-3);

        &:before {
          color: $white;
          content: '\F012C';
          font-family: 'Material Design Icons';
          font-weight: normal;
          position: relative;
          top: 0;
          left: 0;
        }
      }
    }
  }

  &.vertical {
    display: block;

    .wizardItem {
      display: block;
      overflow: visible;
      vertical-align: top;

      .stepsline {
        display: block;
        width: calculateRem(2);
        height: 100%;
        margin: 0;
        @include padding(16, 0, 0, 0);
        left: calculateRem(30);
        top: 0;

        &:after {
          width: calculateRem(2);
          height: 100%;
        }
      }

      .stepsCircle {
        float: left;
        margin-right: calculateRem(15);
        margin-top: 0;
      }

      .wizardContent {
        display: block;
        width: 100%;
        min-height: calculateRem(48);
        text-align: left;
      }

      &.current-forward-nav {
        .stepsline {
          top: 0;

          &:after {
            width: calculateRem(3);
            height: 100%;
            background: $purple;
          }
        }
      }

      &.completed {
        .stepsline {
          top: 0;

          &:after {
            width: calculateRem(3);
            height: 100%;
            background: $purple;
          }
        }

        .stepsCircle {
          top: 0;
        }
      }
    }
  }
}

.legalAdsWrapper {
  header {
    background: $white200;
    @include padding(24, 32, 24, 32);
    margin-bottom: calculateRem(15);
    @include borderRadius(4, 4, 4, 4);
    @include border(1, 1, 1, 1, solid, $white300);
    h6 {
      margin-bottom: calculateRem(4);
      line-height: calculateRem(24);
    }
    h5 {
      margin-bottom: calculateRem(4);
    }
  }

  main {
    h4 {
      margin-top: calculateRem(25);
      padding-bottom: calculateRem(5);
    }
  }

  footer {
    background: $white200;
    @include padding(24, 32, 24, 32);
    @include border(1, 1, 1, 1, solid, $white300, !important);
    position: relative;
    width: 100%;
    margin: auto !important;
    left: 0;
    box-shadow: none;
    @include borderRadius(4, 4, 4, 4);

    p {
      margin-bottom: calculateRem(5);
    }

    small {
      font-size: calculateRem(16);
    }
    table {
      margin-bottom: 0;
    }
  }
}

//Commodity styles

.commoditylist {
  h3,
  h4 {
    @include padding(null, null, 5, null);
    @include margin(null, null, 20, null);
  }
}

.quote-label {
  ul {
    @include padding(10, null, null, null);
    @include margin(null, null, 5, null);
  }

  li {
    display: inline-flex;
    font-size: calculateRem(12);
    width: 18%;
  }
}

.accordin-title {
  background: transparent;
  min-height: calculateRem(40);
  cursor: pointer;
  @include border(0, 0, 1, 0, solid, $lightGray);
  @include padding(null, 10, null, 10);
  position: relative;

  i {
    position: absolute;
    top: calculateRem(3);
    left: calculateRem(-20);
    font-size: calculateRem(22);
    color: $darkTeal;
  }

  p {
    @include padding(8, 10, 8, null);
    @include margin(0, 0, 0, 0);
    display: flex;

    i {
      position: relative;
      left: calculateRem(0);
      top: calculateRem(5);
      float: left;
      @include margin(-3, 10, null, 10);
    }
  }
}

.accordin-band {
  position: relative;
}

.commoditylist {
  .accordin-band {
    @include padding(null, 10, null, 30);
  }

  .quotes-details h6 {
    @include padding(12, 0, 0, 80);
    font-weight: $normal;
    line-height: calculateRem(22);
    position: relative;

    i {
      position: absolute;
      left: calculateRem(48);
    }
  }

  .accordin-band {
    li {
      p {
        display: inline-flex;
        width: 18%;
        font-size: 0.875rem;
        margin-bottom: 0rem;
      }
    }
  }
}

.selectedcommodity {
  display: flex;
  flex-wrap: wrap;
  @include border(0, 0, 1, 0, solid, $lightGray);
}

.commodityinfo {
  flex-basis: 0;
  flex-grow: 1;
  @include padding(0, 20, 0, 0);

  p {
    @include padding(12, 0, 12, 0);
    margin: 0;
  }
}

.deletecommodity i {
  font-size: calculateRem(22);
  @include margin(0, 0, 0, 0);
  @include padding(8, 0, 0, 0);
  cursor: pointer;
  color: $darkTeal;
}

.commodityinfo h6 {
  font-weight: $normal;
  @include margin(null, null, 0, null);
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: $darkTeal;
}

//react select
[class*='css-'][class$='-control'] {
  &:not(.ds-select__control) {
    border: none !important;
    width: calc(100% - 1.5px);
    left: calculateRem(0.7);
    min-height: calculateRem(45.4) !important;
    @include padding(4, null, 4, null);
    border-radius: calculateRem(4) !important;
  }
}

[class*='css-'][class$='-indicatorSeparator'] {
  display: none;
}

[class*='css-'][class$='-input'] {
  border: none !important;
  width: 100% !important;
}

[class*='css-'][class$='-placeholder'] {
  &:not(.ds-select__placeholder) {
    padding-left: calculateRem(14);
    @include sourceSansProItalic(16);
  }
}

[class*='css-'][class$='-singleValue'] {
  &:not(.ds-select__single-value) {
    padding-left: calculateRem(14);
  }
}

[class*='css-'][class$='-menu'] {
  top: calculateRem(36);
  position: absolute;
  z-index: 12 !important;
  box-shadow: none;
}

[class*='css-'][class$='-MenuList'] {
  width: calc(100% - -0.125rem);
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  margin-bottom: 0;
  margin-left: -0.0625rem;
  max-height: calc(100vh - 50vh);
  overflow: auto;
  border-radius: 0 !important;
  box-shadow: none;
  @include border(1, 1, 1, 1, solid, $gray50);
  [class*='css-'][class$='-option'] {
    &:hover {
      background: rgba(51, 149, 164, 0.2);
    }
  }
}

table {
  td {
    .react-select {
      &.tdSelect {
        .custom-react-select {
          [class*='css-'][class$='-singleValue'],
          [class*='css-'][class$='-placeholder'] {
            padding-left: 0;
          }
          [class*='css-'][class$='-menu'] {
            box-shadow: none;
            & > [class*='css-'] {
              position: absolute;
              min-width: calculateRem(200);
              z-index: 111;

              @include border(1, 1, 1, 1, solid, $gray50);
              background: #fff;

              & > [class*='css-'][class$='-option'] {
                cursor: pointer;
                line-height: calculateRem(18);
                margin-bottom: calculateRem(0);
                padding-top: calculateRem(8) !important;
                padding-bottom: calculateRem(8) !important;

                &:first-child {
                  margin-top: calculateRem(5);
                }
              }
            }
          }
        }
      }
    }
  }
}
//Activites styles

.sidebar-area {
  min-height: calc(100vh - 70px);
  overflow: auto;
  @include padding(25, 0, 25, 0);
  background: $o-white200;
}

.quotes-details {
  ul {
    list-style: none;
    padding: 10px 0 0 10px;

    li {
      border-bottom: 0.0625rem solid #e5e5e5;
      padding-bottom: 15px;

      p {
        display: inline-flex;
        width: 18%;
        margin-bottom: 0;
        font-size: 0.875rem;
      }
    }
  }
}

.accordin-band li p:first-child {
  font-size: 1rem;
}

.activities-quote {
  .quote-label {
    ul {
      padding-left: 10px;
    }
  }
}

.activities-nav {
  nav.navbar {
    background: transparent;
    height: auto;
    @include padding(25, 0, 25, 0);
  }

  ul {
    width: 100%;
  }

  li {
    @include padding(12, 47, 12, 47);
    line-height: calculateRem(32);
    cursor: pointer;
    position: relative;

    &:hover {
      background: rgba(255, 255, 255, 0.8);

      &:before {
        content: '';
        left: 0;
        position: absolute;
        background: $teal;
        height: 100%;
        top: 0;
        width: 4px;
      }
    }

    span {
      background: $darkTeal;
      color: $white;
      @include padding(1, 6, 1, 6);
      font-size: calculateRem(14);
      border-radius: calculateRem(10);
      @include margin(0, 0, 0, 5);
      @include border(0, 0, 1, 0, solid, $gray);
    }
  }

  a {
    font-family: $o-firaSans;
    font-size: calculateRem(19);
    font-weight: $semiBold;
    font-style: $normal;
    line-height: calculateRem(30);
    color: $black;
  }

  .active {
    background: rgba(255, 255, 255, 0.8);

    &:before {
      content: '';
      left: 0;
      position: absolute;
      background: $teal;
      height: 100%;
      top: 0;
      width: 4px;
    }
  }
}

.sort-control .selectWrapper {
  display: inline-block;
  @include margin(10, 10, 10, 10);
}

//Conversation Styles

.conversation-time {
  position: relative;

  small {
    background: $white;
    width: max-content;
    @include padding(0, 40, 0, 40);
    margin: 30px auto !important;
    font-style: $italic;
    color: $placeholdergray;
    position: relative;
  }

  &:before {
    content: '';
    position: absolute;
    border-bottom: 1px solid $o-white300;
    width: 100%;
    left: calculateRem(0);
    right: calculateRem(0);
    margin-left: auto;
    margin-right: auto;
    top: calculateRem(9);
  }
}

.padding-zero {
  @include padding(0, 0, 0, 0);
}

.add {
  float: right;
  font-size: calculateRem(24);
  color: $black;
  line-height: calculateRem(45);
}

.sortlist {
  margin-right: 35%;
}

.Conversionwrapper {
  @include margin(35, 0, 0, 0);

  small {
    color: $black;
    background: rgba(255, 255, 255, 0.6);
    @include padding(10, 5, 10, 5);
  }

  h6 {
    @include padding(0, 0, 5, 0);
  }
}

.conversationtab {
  border-top: 1px solid rgba(112, 112, 112, 0.2);
  @include padding(5, 0, 8, 0);
  @include margin(0, 0, 4, 0);

  h6 {
    @include sourceSansProBold(16);
    float: left;
    width: 70%;
    font-size: calculateRem(14);
    @include padding(0, 0, 0, 0);
    border-bottom: 0px solid rgba(112, 112, 112, 0.5);
  }

  em {
    float: right;
    width: 30%;
    text-align: right;
    font-style: $normal;
    font-size: calculateRem(12);
    color: $middleGray;
  }

  small {
    background: transparent;
    @include padding(0, 0, 0, 0);
    font-size: calculateRem(14);
    color: $black;
  }

  p {
    clear: both;
    display: block;
    @include margin(0, 0, 0, 0);
    font-size: calculateRem(14);
    color: $placeholdergray;
  }

  span {
    font-size: calculateRem(12);
    font-weight: $regular;
    @include padding(1, 10, 1, 10);
    border-radius: calculateRem(15);
    @include margin(0, 0, 0, 10);
  }
}

.conversation {
  h5 {
    @include margin(15, 0, 55, 0);
  }

  span {
    float: right;
  }

  button {
    margin-top: 10% !important;
  }
}

//message conversation

.conversation_header {
  h3 {
    @include margin(0, 0, 5, 0);
  }

  h6 {
    @include margin(0, 0, 0, 0);
    color: $darkTeal;
    font-size: calculateRem(14);
  }

  p {
    @include margin(0, 0, 0, 0);
    font-size: calculateRem(14);
    color: $black;

    em {
      font-style: normal;
      font-weight: 600;
      padding-right: 10px;
      color: $o-black;
    }
  }

  small {
    font-size: calculateRem(14);

    i {
      font-style: normal;
      @include padding(0, 5, 0, 5);
      text-decoration: underline;
      color: $darkTeal;
      font-size: calculateRem(12.8);
    }
  }
}

.conversation-bottom {
  background: $white;
  position: absolute;
  width: 100%;
  bottom: calculateRem(0);
  left: calculateRem(0);
  @include padding(10, 0, 3, 0);
  box-shadow: inset 0 20px 7px -20px rgba(0, 0, 0, 0.3);
}

.messagetitle {
  width: 90%;

  span {
    float: right;
    color: $placeholdergray;
    cursor: pointer;
  }

  p {
    text-align: justify;
    font-size: calculateRem(12.8);
  }
}

.conversation_close {
  p {
    float: right;
    cursor: pointer;
    @include padding(0, 10, 0, 10);

    &:hover {
      text-decoration: underline;
    }

    span {
      @include padding(0, 5, 0, 5);
      position: relative;
      font-size: calculateRem(16);
      top: calculateRem(2);
    }
  }
}

//message area

.messagebar {
  h2 {
    border-bottom: 1px solid $gray;
    @include padding(0, 0, 5, 0);
    color: $black;
    @include margin(5, 0, 0, 0);
  }
}

.messagearea {
  @include margin(30, 80, 0, 80);

  small {
    text-align: right;
    @include margin(3, 0, 0, 0);
    color: $placeholdergray;
    font-style: $italic;
  }

  p {
    border: 1px solid rgba(112, 112, 112, 0.2);
    @include padding(10, 10, 10, 10);
    border-radius: calculateRem(4);
    @include margin(0, 0, 0, 0);
    background: $o-white300;
    background: $teal;
    color: $white;
  }

  .receivedmessage {
    position: relative;
    left: calculateRem(20);

    p {
      small {
        color: rgba(255, 255, 255, 0.8);
      }
    }

    .participantname {
      right: calculateRem(-95);
    }
  }

  .sendermessage,
  .receivedmessage {
    display: flex;
    @include margin(0, 0, 10, 0);
    position: relative;

    .participantname {
      width: 13%;
      position: absolute;
      top: calculateRem(0);

      img {
        margin: 0 auto;
        display: table;
      }
    }
  }

  .messagedescription {
    width: 100%;
    @include margin(0, 0, 0, 0);

    p {
      small {
        text-align: left;
        @include margin(0, 0, 0, 0);
        font-style: $normal;
      }
    }
  }

  .sendermessage {
    position: relative;
    left: calculateRem(0);

    .participantname {
      left: -95px;

      small {
        text-align: left;
        @include margin(0, 0, 0, 10);
      }
    }

    p {
      background: transparent;
      color: #343a40;

      small {
        color: $placeholdergray;
      }
    }
  }
}

.chatbox {
  position: relative;

  .textbox-wrapper {
    border-radius: calculateRem(4);
    @include padding(5, 0, 5, 0);

    .errorMsg {
      display: none;
    }
  }

  .inputWrapper {
    @include margin(0, 0, 0, 0);
  }

  .inputWrapper div textarea {
    height: calculateRem(52);
    @include padding(0, 15, 0, 15);
    width: 100%;
    @include margin(0, 0, 0, 0);
  }

  i {
    display: none;
  }

  label {
    display: none;
  }

  button {
    position: absolute;
    top: 0;
    right: calculateRem(0);
    @include margin(0, 0, 0, 0);
    @include padding(10, 8, 10, 8);
    min-width: calculateRem(110);
    font-size: calculateRem(18);
    font-weight: $semiBold;
    border-radius: 0px 5px 5px 0px;
    border-color: $teal;
    color: $white;
  }
}

.btn-connect {
  width: 80%;
  position: relative;
  margin: 0 auto;

  .mdi-send {
    &::before {
      @include margin(null, 5, null, null);
      position: relative;
      top: calculateRem(2);
    }
  }
}
// Required Doc List
.reqDocList {
  li {
    @include sourceSansProRegular(14);

    img {
      width: calculateRem(20);
    }
  }
}

// Agency Bid Details
.ulListWrapper {
  header {
    border-bottom: calculateRem(1) solid $o-white300;
    @include padding(8, 16, 8, 16);

    span {
      cursor: pointer;

      &.staticLink {
        @include sourceSansProSemiBold(16);
      }

      i {
        position: relative;
        top: 0px;
        right: 3px;
        color: $teal;
      }
    }
  }

  .table {
    &.tableHData {
      tr {
        @include border(0, 0, 0, 5, solid, transparent);
        &.active {
          border-color: $purple;
        }
        td {
          padding-left: calculateRem(8);
        }
      }
    }
  }

  .list-unstyled {
    li {
      @include padding(16, 16, 16, 16);
      border-left: calculateRem(5) solid transparent;
      border-bottom: calculateRem(1) solid $o-white300;
      cursor: pointer;
      margin-bottom: 0 !important;

      &.active {
        border-left: calculateRem(5) solid $purple;
        background: $o-white100;
      }

      &:hover {
        border-left: calculateRem(5) solid $gray;
        background: $o-white300;
      }

      h6 {
        margin-bottom: 0;
      }

      span {
        @include sourceSansProRegular(14);
        color: $placeholdergray;
        line-height: calculateRem(20);
        display: block;

        i {
          color: $black;
          font-style: normal;
        }
      }
    }
  }
}

.blockControls {
  display: block;
  width: 100%;

  ul {
    float: right;

    li {
      display: inline-block;
      cursor: pointer;
      margin-left: calculateRem(32);
      @include sourceSansProSemiBold(16);
      color: $teal;

      i {
        position: relative;
        top: 2px;
        right: 2px;
      }
    }
  }
}

// Bids counts wrapper
.countsWrapper {
  margin-top: calculateRem(32);

  h2 {
    margin-bottom: calculateRem(4);
  }
}

//Audit Trail
.auditTrailWrapper {
  .headerFilter {
    .selectWrapper {
      margin-left: 0;

      & > div {
        .dd-wrapper {
          @include padding(8, 0, 8, 0);

          .dd-list {
            top: calculateRem(42);
          }
        }
      }
    }
    .inputWrapper {
      margin-bottom: calculateRem(0);
      margin-top: 0;
      div {
        label {
          display: block;
        }
        input {
          min-height: calculateRem(45);
        }
      }
    }
    .datePickerWrapper {
      margin-bottom: calculateRem(-21) !important;
    }
  }
}

// e-bid envelope
.envelopeWrapper {
  img {
    height: calculateRem(800);
  }

  .envelopeTxt {
    position: absolute;
    max-width: calculateRem(547.2);
    min-height: calculateRem(180);
    text-align: center;
    background: $lightGray;
    width: calc(100vw - 169px);

    @include padding(20, null, 20, null);

    h3 {
      color: $red;
    }
  }
}

//Dashboard
.dashboardWrapper {
  .chartWrapper {
    width: 100%;
    height: calculateRem(300);
    background: $o-white200;
    margin-bottom: calculateRem(16);
    @include padding(24, 24, 24, 24);
    & > div {
      & > div {
        top: calculateRem(-48);
      }
    }
  }

  .notificationWrapper {
    background: $o-white200;
    height: 100%;
    @include padding(16, 16, 16, 16);

    h2 {
      line-height: calculateRem(38);
    }

    h3 {
      margin-bottom: calculateRem(18);
    }

    .notificationClose {
      margin-bottom: calculateRem(18);

      span {
        @include padding(4, 12, 4, 12);

        i {
          cursor: pointer;
          margin-left: calculateRem(10);
        }
      }
    }

    .notificationList {
      background: $white50;
      @include padding(8, 12, 8, 12);
      margin-bottom: calculateRem(8);

      @include border(1, 1, 1, 1, solid, $white500);

      .tag {
        @include sourceSansProSemiBold(14);
      }

      h6 {
        line-height: calculateRem(24);
        margin-bottom: calculateRem(2);
        margin-top: calculateRem(10);
      }

      i {
        float: right;
        color: $gray200;
        cursor: pointer;
        &.mdi-close {
          font-size: calculateRem(20);
        }
        &.mdi-launch {
          position: relative;
          top: calculateRem(-18);
        }
      }

      p {
        margin-bottom: calculateRem(6);
        color: $gray200;
        font-size: calculateRem(15);
      }
    }
    .commodityWrapper {
      background: $o-white200;
      margin-left: calculateRem(-20);
      margin-right: calculateRem(-20);
    }
  }

  .dbCountWrapper {
    background: $o-white200;
    text-align: center;
    height: 100%;
    min-height: calculateRem(140);
    max-height: calculateRem(155);
    @include padding(14, 8, 14, 8);

    h6 {
      color: $black;
      @include firaSansSemiBold(16);
      @include margin(0, 0, 8, 0);
      line-height: calculateRem(20);

      i {
        display: block;
        font-style: normal;
        @include sourceSansProRegular(12.8);
      }
    }

    & > span {
      color: $black;
      @include firaSansSemiBold(40);
      @include border(0, 0, 3, 0, solid, $teal);
      @include padding(0, 8, 0, 8);
    }

    div {
      color: $black;
      @include sourceSansProRegular(12);
      @include margin(4, 0, 8, 0);

      & > span {
        color: $black;
        @include firaSansSemiBold(18);
        @include padding(0, 8, 0, 8);
      }
    }
  }

  .dashboardSidebar {
    .accordin-title {
      border: none;

      h6 {
        cursor: pointer;
        color: $darkTeal;
        @include firaSansBold(14.22);
        margin-top: calculateRem(8);
        padding-left: calculateRem(12);

        i {
          font-size: calculateRem(16);
          left: 0;
          top: calculateRem(0);
        }

        span {
          display: inline-block;
          color: $black;
        }
      }
    }

    .collapsing {
      border: none;
      margin: 0;
      padding: 0;
    }

    .collapse,
    .collapsing {
      .listGroupWrapper {
        background: none;
        @include padding(8, 8, 8, 0);
        @include margin(0, 0, 0, 19);

        a {
          @include firaSansSemiBold(13.5);
          width: 96%;
          display: block;
        }

        p {
          @include sourceSansProRegular(15);
          width: 96%;
        }
      }

      &.show {
        .listGroupWrapper {
          background: none;
          @include padding(8, 8, 8, 0);
          @include margin(0, 0, 0, 19);

          a {
            @include firaSansSemiBold(13.5);
            width: 96%;
            display: block;
          }

          p {
            @include sourceSansProRegular(15);
            width: 96%;
          }
        }
      }
    }
  }

  .dashboardWrapperInner {
    padding: 0;
  }

  .dashboardLeft {
    float: left;
    @include padding(0, 4, 0, 0);
    margin-right: calculateRem(5);

    .infoblocks {
      width: 100%;
      float: left;
      background: $teal;
      text-align: center;
      color: white;
      border-radius: calculateRem(2);
      height: calculateRem(200);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: calculateRem(15);

      h2 {
        margin-bottom: 0.5rem;
        font-size: 3rem;
      }

      p {
        font-size: 1.2rem;
      }
    }
  }

  .dashboardRight {
    background: $teal;
    float: left;
    border-radius: calculateRem(2);
    color: white;
    height: calculateRem(415);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-right: calculateRem(10);

    h1 {
      font-size: 6rem;
    }

    p {
      font-size: 2rem;
      line-height: 3rem;
      text-align: center;
      padding: 3%;
    }
  }

  .commodityWrapper {
    background: $o-white200;
    margin-left: calculateRem(0);
    margin-right: calculateRem(0);
  }
}

.generateNewList {
  margin-left: calculateRem(-64);
  margin-right: calculateRem(-64);

  .colWrapper {
    padding: 0;
    border: none;

    & > h4 {
      font-weight: 600;
    }
  }

  .sideBarSpacing {
    padding: 0;
    border: none;

    .checkControl {
      text-align: left !important;
    }

    .inputWrapper {
      margin-top: calculateRem(24);
    }

    & > h4 {
      font-weight: 600;
    }
  }
}

// Braintree
.braintreeWrapper {
  .braintree-form__field-group {
    & > label {
      width: 100%;

      .braintree-form__field {
        & > div {
          @include borderRadius(4, 4, 4, 4);
        }
      }
    }
  }
}

// Coming Soon
.comingSoonWrapper {
  h1 {
    @include firaSansSemiBold(44);
    line-height: calculateRem(48);
    margin-bottom: calculateRem(24);
  }
  p {
    @include sourceSansProRegular(18);
    line-height: calculateRem(24);
    margin-bottom: calculateRem(24);
  }
  ol {
    counter-reset: orderedlist;
    li {
      font-size: calculateRem(24);
      counter-increment: orderedlist;
      margin-bottom: calculateRem(18);
      color: $darker-yellow;
      & > span {
        color: $o-black;
        @include sourceSansProRegular(18);
        line-height: calculateRem(24);
      }
    }
  }
}

[data-braintree-id='toggle'] {
  display: none;
}

@media print {
  a,
  .staticLink {
    color: $o-black !important;
  }
}

// No Auth CSS
.mainNoWrapper {
  a {
    color: $teal;
    &:hover {
      color: $teal;
    }
  }
  .headerBreadcrumbWrapper {
    min-height: calculateRem(74);
    .navbar-light {
      .navbar-brand {
        svg {
          fill: $white50;
        }
      }
    }
  }
  .footerWrapper {
    a {
      color: $o-black;
    }
  }
  .contentNoWrapper {
    height: 100%;
    min-height: calc(100vh - 166px);
  }
}

// Agency Reg. Product list

.productsList {
  li {
    &::before {
      content: '\F0142';
      font-family: 'Material Design Icons';
      font-weight: normal;
      font-size: calculateRem(18);
      line-height: calculateRem(19);

      color: $darkTeal;
      @include padding(0, 2, 0, 2);
      position: relative;
      top: calculateRem(3);
    }
  }
}

/*spinkit/ */
.spinner {
  display: inline-block;
}

.spinner > div {
  width: 4px;
  height: 4px;
  background-color: #333;
  margin-left: 4px;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.freeAgencyList {
  max-height: calc(100vh - 65vh);
  height: calc(100vh - 65vh);
  overflow-y: auto;
}

.unAuthWrapper .freeAgencyList {
  height: calculateRem(400);
  max-height: calculateRem(400);
  overflow-y: auto;
}

// btn alignmet to title
.btnPTitle {
  margin-top: calculateRem(-18) !important;
  &.react-select {
    margin-bottom: 0;
    width: calculateRem(180);
    .errorMsg {
      display: none;
    }
  }
}

// Activities Filter
.activitiesFilter {
  .react-select {
    width: calculateRem(150);
    margin-right: calculateRem(16);
    .errorMsg {
      display: none;
    }
  }
  .selectWrapper {
    min-width: calculateRem(200);
    margin-right: calculateRem(16);
    .errorMsg {
      display: none;
    }
  }
  .inputWrapper {
    width: calculateRem(350);
    margin-right: calculateRem(16);
    flex: 1;
    .errorMsg {
      display: none;
    }
  }
  .bttn-primary {
    margin-right: calculateRem(8);
  }
}

// agency landing page logo and image preview
.previewWrapper {
  margin-left: -20px;
  margin-right: -20px;
  .alBannerWrapper {
    width: 100%;
    min-height: 360px;
    background-repeat: no-repeat;
    background-color: $gray50;
    background-position: center center;
    position: relative;
    .alTitleLogo {
      background-color: rgba(63, 63, 63, 0.81);
      height: 120px;
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      padding-bottom: 20px;
      padding-top: 20px;
      border-bottom: 1px solid #8e8b8b;
      .container {
        max-width: 1140px;
      }
      .alLogo {
        display: inline-block;
        margin-right: 20px;
        background: #bcbcbc;
        padding: 5px;
        border: 2px solid #e4e1dd;
        height: 120px;
        max-width: 200px;
        min-width: 110px;
        text-align: center;
        & > div {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
        }
        img {
          max-width: 180px;
          max-height: 108px;
        }
      }
      .alName {
        flex: 1;
        h3,
        h4 {
          font-family: 'Fira Sans', sans-serif;
          color: #fff !important;
        }

        h3 {
          font-size: 32px;
          line-height: 38px;
          font-weight: 500;
          margin-bottom: 0;
        }
        h4 {
          font-size: 20px;
          line-height: 22px;
        }
      }

      .poweredBy {
        position: relative;
        right: 0;
        bottom: 25px;
        font-size: 12px;
        color: #c3bdbd;
        text-align: right;
        img {
          margin-bottom: -4px;
        }
      }
      .alInnerCol {
        min-height: 80px;
        display: flex;
        align-items: center;
        margin-top: -20px;
      }
    }
  }
}

.unAuthWrapper {
  padding-top: calculateRem(50);
  min-height: calc(100vh - 350px);
  margin-bottom: 30px;
  &.unAuthTabWrapper {
    padding-top: calculateRem(100);
  }
}

// ebid campagin
.docControls {
  .staticLink {
    margin-left: 11% !important;
  }
}

.searchToggle {
  text-align: center;
  position: absolute;
  margin-left: 0;
  margin-right: 2px;
  top: -190px;
  z-index: 1;
  height: calculateRem(180);
  h5 {
    margin-top: 175px;
    &::after {
      content: '';
      height: 2px;
      position: absolute;
      top: calculateRem(190);
      left: 0;
      width: 100%;
      z-index: -1;
    }
    & > label {
      cursor: pointer;
      padding-left: calculateRem(4);
      padding-right: calculateRem(4);
    }
  }
  span {
    height: 2px;
    background-color: $purple;
    width: 100%;
  }
  i {
    font-size: 38px;
    cursor: pointer;
    color: $purple;
    position: relative;
    top: calculateRem(98);
  }
  &.searchExp {
    background: none;
    position: absolute;
    top: 0;
    height: auto;
    z-index: 0;
    h5 {
      margin-top: 0;

      &::after {
        top: calculateRem(16);
      }
      & > label {
        cursor: pointer;
        padding-left: calculateRem(4);
        padding-right: calculateRem(4);
      }
    }
    i {
      bottom: 0;
      top: 0;
    }
  }
}

.skipColWrapper {
  margin-bottom: calculateRem(16);
  max-height: calculateRem(400);
  .skipCol {
    @include border(1, 1, 1, 1, solid, $white500);
    @include padding(16, 16, 16, 16);
    min-height: calculateRem(260);
    max-height: calculateRem(400);
    min-width: calculateRem(234);
    max-width: calculateRem(234);
    margin-left: calculateRem(16);
    margin-bottom: calculateRem(16);
    h6 {
      font-size: calculateRem(14);
    }
    &:first-child {
      margin-left: 0;
    }
    & > div {
      width: 100%;
      &.mb-auto {
        @include firaSansSemiBold(17);
      }
    }
    i {
      display: block;
      font-style: normal;
      font-size: calculateRem(14);
    }
    span {
      display: inline-block;
    }
    .react-select {
      .errorMsg {
        display: none;
      }
    }
    .selectWrapper {
      margin-top: 0;
    }
    & > ul {
      margin-bottom: 0;
      width: 115%;
      position: relative;
      left: calculateRem(-16);
      li {
        @include border(1, 0, 0, 0, solid, $white500);
        width: 100%;
        @include padding(5, 16, 5, 16);
        word-break: break-all;
        line-height: calculateRem(18);
      }
    }
  }
}

.reviewListWrapper {
  h5 {
    margin-bottom: calculateRem(26);
  }
  .reviewScroll {
    min-height: calculateRem(180);
    max-height: calc(100vh - 350px);
    overflow-y: auto;
    overflow-x: hidden;
    margin-bottom: calculateRem(32);
    table {
      tr {
        th {
          vertical-align: top;
          i {
            @include sourceSansProRegular(16);
            font-style: italic !important;
          }
        }
      }
    }
    .list-unstyled {
      &.row {
        li {
          word-break: break-all;
          line-height: calculateRem(18);
          margin-bottom: calculateRem(14);
        }
      }
    }
  }
}

.filterTags {
  h6 {
    position: relative;
    top: calculateRem(-7);
    margin-right: calculateRem(8);
  }
  .tag {
    width: auto;
    min-width: auto;
    max-width: calculateRem(260);
    display: inline-block;
    margin-right: calculateRem(5);
    margin-left: 0;
    @include firaSansSemiBold(14);
    padding-left: calculateRem(8);
    padding-right: calculateRem(8);
    line-height: calculateRem(23);
    &.draft {
      color: $gray300 !important;
    }
    span {
      max-width: calculateRem(225);
      display: inline-block;
    }
    i {
      color: $gray50;
      display: inline-block;
      position: relative;
      cursor: pointer;
      top: calculateRem(-6);
    }
  }
}

.skipColContainer {
  #bulkUploadScroll {
    width: calculateRem(654);
    max-height: calculateRem(400);
    overflow-x: auto;
  }
}

.tourMask {
  color: rgba(21, 20, 20, 0.45);
}
.tourInfoBox {
  color: $o-black;
  & > span {
    left: auto;
    right: calculateRem(-35);
  }
  & > div {
    display: flex;
    justify-content: space-between;
    button {
      span {
        button {
          background-color: $teal;
          color: $white50;
          @include firaSansSemiBold(14);
          box-shadow: none;
          @include borderRadius(4, 4, 4, 4);
          border: none;
          @include padding(6, 8, 6, 8);
          line-height: calculateRem(11);
        }
      }
      &:focus {
        outline: none;
      }
      &:disabled {
        span {
          button {
            background: $gray100;
            cursor: not-allowed;
          }
        }
      }
    }
  }
}

/* new registration css */
.regWrapper {
  /* sorry to create yet another wrapper, but don't want input changes to affect across the board, or to affect previously existing styles under NewRegWrapper */

  p {
    @include sourceSansProRegular(18);
    line-height: calculateRem(24);
    padding-top: 28px; // keep an eye on this; added for 1st page

    &.no-top-padding {
      padding-top: 0;
    }

    &.reg-intro {
      padding-bottom: 28px;

      a {
        text-decoration: underline;
        @include sourceSansProRegular(18);
      }
    }
  }

  .formHelpText {
    color: $o-gray100;
    @include sourceSansProRegular(14);
  }

  label {
    @include sourceSansProBold(14.22);
    margin-bottom: 0;
  }

  .inputWrapper {
    margin-bottom: 0;
  }

  .inputWrapper label,
  .react-select label {
    position: relative;
    left: 0;
    top: 0;
    padding-left: 0;

    &::after {
      background: none;
    }
  }

  input {
    padding-left: 0;
  }

  .reg-search-reset {
    text-decoration: underline;
  }

  .reg-agency-list {
    padding-top: 1rem;
    padding-bottom: 2rem;
  }

  .reg-paginate {
    .pagination-label {
      padding-top: 2rem;
    }
  }

  .reg-span {
    @include sourceSansProBold(18);
  }

  .reg-info-block,
  .billing-info-block {
    padding-top: 2rem;
    padding-bottom: 0;

    h4 {
      margin-bottom: 0;
    }

    p {
      padding-top: 0;
      margin-bottom: 0;
    }
  }

  .billing-info-block {
    padding-top: 0;
  }

  .bttn-as-link {
    background: none;
    border: none;
    color: $dark-teal;
    color: $dark-teal;
    text-decoration: underline;
    @include sourceSansProBold(18);
  }

  // override old styles
  .react-select > div:first-child {
    border: none;
  }

  .react-select {
    margin-top: 0;
    margin-bottom: 0;

    div {
      .custom-react-select {
        border: 1px solid #b3b3b3;
        border-radius: 4px;
      }
    }
  }
  .react-select.errorWrapper {
    div {
      .custom-react-select {
        border-color: #dc3826;
      }
    }
  }

  .radioControl,
  .checkControl {
    @include sourceSansProRegular(18);

    p {
      margin: 0;
      padding: 0;
    }
  }

  h4:after {
    border: none;
  }

  // created for checkout page h4s
  .no-bottom-margin {
    margin-bottom: 0;
  }

  .staticLink {
    text-decoration: underline;
    @include sourceSansProRegular(18);
  }
}

.newRegWrapper {
  margin: auto;
  .colWrapper {
    padding: 0;
    border: none !important;
    min-height: auto;
    .regCaption {
      background: $darker-teal;
      padding: 18px 20px 18px 20px;
      text-align: center;
      color: $white50;
      @include sourceSansProSemiBold(26);
    }
    .createWrapper {
      background: #fff;
      border: none;
      .createAccWrapper {
        height: 87.6%;
      }
    }
    .dividerClr {
      background: #fafafa;
      border-right: 1px solid #eaeaea;
      padding-top: 40px;
      padding-left: 0;
      padding-right: 0;
      .prodPrice {
        padding: 20px 0 0 0;
        margin: 0px 40px;
        justify-content: center;

        &:last-child {
          padding-bottom: 2rem;
        }
        .col-4 {
          text-align: right;
        }
        h4 {
          @include firaSansSemiBold(20);
          text-align: center;
          margin-bottom: 0;
          &:after {
            border: none;
          }
        }

        h3 {
          @include firaSansSemiBold(22);
          line-height: calculateRem(20);
          margin-bottom: 0;
          text-align: center;
        }

        span {
          @include sourceSansProRegular(16);
          display: block;
          margin-bottom: 7px;
          margin-top: 7px;
        }
      }
    }
  }
  p {
    @include sourceSansProRegular(18);
    line-height: calculateRem(24);
    padding-top: 24px; // keep an eye on this; added for 1st page
  }

  .formHelpText {
    color: $o-gray100;
    @include sourceSansProRegular(14);
  }

  label {
    @include sourceSansProBold(14.22);
    margin-bottom: 0;
  }

  .createWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

/* cart-specific. */
.cart-container {
  //corrects cart alert misalignment
  .row {
    margin-right: 0;
    margin-left: 0;
  }

  //make the icon the correct size
  .alert-iconbox {
    padding: 0;
  }
}
.cart-list {
  //reset <ul> margin so that if you combine state + county it doesn't lay out oddly
  margin-bottom: 0;

  li {
    margin-bottom: 0.5rem;
    display: flex;
  }

  p {
    padding-top: 0;
    margin-bottom: 0;
  }

  //three-column layout in the cart
  .cart-col {
    padding-top: 0;
    width: 25%;

    &:first-child {
      width: 50%;
      padding-right: 2rem;
    }

    &:last-child {
      width: 25%;
      float: right;
      text-align: right;
    }
  }

  //price style & alignment with icon
  .reg-cart-price {
    text-align: right;
  }

  .reg-icon-box {
    padding-top: 1px;
    margin-left: 4px;
    cursor: pointer;
  }
}

@media screen and (max-width: 768px) {
  .cardMobile {
    display: none !important;
  }
}
@media screen and (max-width: 768px) {
  .hideForMobile {
    display: none !important;
  }
}

.showForMobile {
  display: inline-block !important;
   margin-top: -2rem;

}

@media screen and (min-width: 769px) {
  .showForMobile {
    display: none !important;
  }
}

@media screen and (max-width: 769px) {
  .mobileArrowWrapper {
   font-size: calculateRem(20)
  }
}

@media screen and (max-width: 625px) {
  .mobileArrowWrapper {
   font-size: calculateRem(17)
  }
}

@media screen and (max-width: 450px) {
  .mobileArrowWrapper {
   font-size: calculateRem(14)
  }
}


//general utility, as <strong> is deprecated html
//should eventually pull it out into global css
.bold {
  @include sourceSansProBold(18);
}
/* end cart-specific. */

.downloadDocs {
  margin-bottom: 0.5rem;
}
button.scrollButton {
  width: calculateRem(40);
  @include borderRadius(3, 3, 3, 3);
  &:focus {
    outline: none;
  }
  i {
    font-size: calculateRem(38);
    position: relative;
    right: calculateRem(5);
  }
}
.scrollDummyDiv {
  width: calculateRem(40);
}

.bidReportScrollContainer {
  display: flex;
  .scrollButton,
  .scrollDummyDiv {
    min-height: 1px;
  }
  #bidReportTable {
    width: calc(100% - 82px);
  }
}

#DemandStar_-_Logo-Loader {
  path {
    fill: transparent;
    &.highlight {
      fill: $teal;
    }
  }
}

.tagsPencil{
  opacity: 0;
}
.badge:hover{
  .tagsPencil{
    opacity: 1;
  }
}

@media  screen and (max-width: 768px)  {
  .mobileAlignCenter{
    display: flex !important;
    justify-content: center !important;
    margin-bottom: 10px;
  }
}

.card-info {
  margin-bottom: 35px;
  .default-status {
    min-width: 114px;
    max-width: 210px;
    border: 1px solid #197582;
    color: #197582;
    padding-right: 16px;
    padding-left: 16px;
    text-align: center;
    margin-top: -21px;
    margin-right: 24px;
    margin-left: 24px;
    border-radius: 10px;
    font-weight: 600;
    font-style: normal;
  }
  .btn-default {
    margin-top: -24px !important;
  }

  .card-transaction {
    h4 {
      overflow: hidden;

      &:after {
        content: '';
        display: inline-block;
        width: 100%;
        height: 100%;
        margin-right: -100%;
        border-bottom: 2px solid $lightGray;
        position: relative;
        top: -3px;
        left: 5px;
      }
    }
  }
}
.blurred {
  filter: blur(3px);
  user-select: none; 
  pointer-events: none;
}

.tags-input-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;
  margin-top: 8px;
}
.search-tag {
  display: flex;
  align-items: center;
  background-color: #e0e0e0;
  border-radius: 16px;
  padding: 0px 8px;
  font-size: 13px;
  width: max-content;
  margin-bottom: 1rem;
}
.selected-tag {
  display: flex;
  align-items: center;
  background-color: #ffd67d;
  border-radius: 16px;
  padding: 0px 8px;
  font-size: 13px;
  width: max-content;
  margin-bottom: 1rem;
}
.crossMark {
  cursor: pointer;
  margin-left: 4px;
}
.tagsBlock{
  max-height:200px;
  overflow-y:auto;
}
.commodityBlock{
  max-height: 37vh;
  overflow-y: auto;
  overflow-x: hidden;
}
.relevantCommodityBlock{
  overflow-y: auto;
  max-height: 40vh;
  overflow-x: hidden;
}
.scrollable-modal-body {
  max-height: 79vh; 
  overflow-y: auto;
  overflow-x: hidden;
}
.customMargin{
  margin-top: -20px;
  margin-bottom: 15px;
}
