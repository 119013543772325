@import '../utils/variables.scss';
@import '../utils/mixins.scss';

.breadcrumbWrapper {
	@include padding(4, 10, 4, 10);
	min-height: calculateRem(48);
	// margin-bottom: calculateRem(15);

	// padding: 8px 10px;
	.breadcrumb {
		width: 100%;
		background: none;
		@include sourceSansProSemiBold(16);
		line-height: calculateRem(24);
		@include padding(8, 0, 0, 0);
		margin-bottom: 0;

		.breadcrumb-item {
			color: $black;
			padding-left: 0;

			&.textWrapp {
				max-width: calc(100% - 60%);
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}

			&.active {
				color: $black;
				@include sourceSansProSemiBold(16);
				line-height: calculateRem(19.5);

				&.textWrapp {
					max-width: calc(100% - 20%);
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
				}
			}

			a {
				@include sourceSansProSemiBold(16);
				line-height: calculateRem(16);
				color: $darkTeal;
				text-decoration: underline;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;

				i {
					font-size: calculateRem(14.5);
					line-height: calculateRem(17);

					&.mdi-home-variant-outline {
						position: relative;
						top: calculateRem(1);
					}
				}
			}

			&:first-child:before {
				content: '';
			}

			&::before {
				content: '\F0142';
				// font: normal normal normal 18px/19px 'Material Design Icons';
				font-family: 'Material Design Icons';
				font-weight: normal;
				font-size: calculateRem(18);
				line-height: calculateRem(19);
				// display: inline-block;
				// font: normal normal normal 24px/1 'Material Design Icons';
				// font-size: inherit;
				// text-rendering: auto;
				// line-height: inherit;
				// -webkit-font-smoothing: antialiased;
				// -moz-osx-font-smoothing: grayscale;
				color: $darkTeal;
				@include padding(0, 2, 0, 2);
				position: relative;
				top: calculateRem(1);
			}
		}

		&.pageControl {
			// width: calculateRem(500);
			// display: flex;
			// align-items: flex-end;
			display: inline-block;
			width: auto;
			padding-top: 0;
			position: relative;
			top: calculateRem(3);
			right: calculateRem(8);

			li {
				// @include sourceSansProSemiBold(13);
				// flex: 1;
				@include margin(null, 8, null, null);
				@include padding(7, 8, 7, 8);
				float: left;
				width: auto;
				text-align: left;
				background: $teal;
				@include borderRadius(4, 4, 4, 4);

				a {
					color: $white;

					// padding-left: 8px;
					// margin-left: 15px;
					@include sourceSansProSemiBold(16);
					line-height: calculateRem(16);
					text-decoration: underline;
					float: right;
					cursor: pointer;

					&:hover {
						// text-decoration: none;
					}

					i {
						// font-size: calculateRem(16);
						font-size: calculateRem(23);
						line-height: calculateRem(12);
						position: relative;
						z-index: 111;
						float: left;
						left: 0;
						top: calculateRem(3);
					}
				}

				span {
					display: block;
					@include sourceSansProSemiBold(16);
					// line-height: calculateRem(21);
					line-height: calculateRem(16);
					color: $o-gray50;

					.staticLink {
						color: $white;
					}

					&.addBid {
						@include sourceSansProSemiBold(16);
					}

					i {
						font-size: calculateRem(23);
						line-height: calculateRem(16);
						position: relative;
						z-index: 111;
						float: left;
						left: 0;
						// top: calculateRem(3);
						top: calculateRem(1);
					}
				}

				&.disabled-bttn {
					background: $dark-coolgray !important;
					cursor: no-drop;

					span {
						color: $gray300 !important;
					}
				}

				.dropdown {
					line-height: 0;
					button {
						&.btn-secondary {
							background: none;
							padding: 0;
							border: none;
							&:focus {
								outline: none;
								box-shadow: none;
							}
						}
					}
					.dropdown-menu {
						left: auto !important;
						margin-left: calculateRem(-99) !important;
						top: calculateRem(4) !important;
						background: $white500;
						border-radius: 0;
						@include padding(10, 0, 10, 0);
						.dropdown-item {
							// @include sourceSansProRegular(20);
							@include sourceSansProSemiBold(20);
							line-height: calculateRem(24);
							text-decoration: none;
							@include padding(6, 20, 6, 20);
							@include margin(2, 0, 2, 0);
							background: $white500;
							color: $dark-teal !important;
							&:focus {
								outline: none;
							}
						}
					}
				}
			}
		}
	}
}
