/* New Header and Footer */
.custom-logo {
	display: inline-block;
	width: 100%;
	max-width: 272px;
}
.d-flex.flex-column.flex-md-row.align-items-center.p-3.px-md-4.bg-white.border-bottom.shadow-sm.business-header {
	box-shadow: -3px 3px 5px rgba(0, 0, 0, 0.3);
}
.business-header {
	padding-left: 0;
	padding-right: 0;
	box-shadow: -3px 3px 5px rgba(0, 0, 0, 0.3);
	position: fixed;
	width: 100%;
	z-index: 999;
}
.business-header .container-fluid {
	padding-left: 72px;
	padding-right: 72px;
}

.business-header nav a {
	font-family: 'Fira Sans', sans-serif;
	color: #333;
	font-size: 19px;
	font-weight: 600;
	padding: 0px 35px 0 0;
}
.business-header nav a:hover {
	transition: 0.2s;
	color: #3395a4;
	text-decoration: none;
}

.business-header .navBtn {
	border: 2px solid #3395a4;
	box-sizing: border-box;
	border-radius: 5px;
	background: none;
	color: #3395a4;
	width: 132px !important;
}
.login-toolbar {
	border: 2px solid #3395a4;
	box-sizing: border-box;
	border-radius: 5px;
	background: none;
	color: #3395a4;
	width: 132px !important;
	font-size: 18px;
	font-style: normal;
	font-weight: 600;
	line-height: 46px;
	white-space: nowrap;
	padding: 0 14px;
	font-family: 'Fira Sans', sans-serif;
	margin: 0 10px;
}
.login-toolbar:hover {
	background: #197582;
	border: 2px solid #3395a4;
	color: #fff;
}
.p-3 {
	padding: 0.6rem 3rem !important;
}

.business-footer {
	background: #fff;
	color: #3f3f3f;
	font-family: 'Fira Sans';
	padding-top: 65px !important;
	padding-left: 95px;
	padding-right: 95px;
	border: none !important;
}
a.footer-mail {
	color: #3f3f3f;
}
.business-footer span,
.business-footer span:hover {
	color: #3f3f3f !important;
	text-decoration: none;
}
.business-footer h5 span {
	font-weight: 600;
	font-size: 24px;
	font-family: 'Fira Sans';
	color: #3f3f3f !important;
}
.business-footer h5 span:hover {
	font-family: 'Fira Sans';
}

.business-footer ul,
.business-footer a,
.business-footer p {
	margin-top: 1.5em;
}
.business-footer a,
.business-footer p {
	color: #3f3f3f;
	line-height: 32px;
	font-size: 19.2px;
	font-weight: 400;
	font-family: 'Source Sans Pro', sans-serif;
}
.business-footer a:hover {
	font-family: 'Source Sans Pro', sans-serif;
}
.copyrights-band ul {
	float: right;
	margin-right: 35px;
}
.copyrights-band ul li {
	display: inline;
	padding-right: 1em;
	font-family: 'Fira Sans', sans-serif;
}
.row.copyrights-band p {
	font-size: 19.2px;
	text-align: center;
	margin-bottom:0;
}
.limted-footer:hover{
	text-decoration: underline !important;
}
.widget.widget_media_image {
	float: left;
}
.widget.widget_media_image img {
	width: 64px;
	margin-top: 5em;
	height: 64px;
	margin-right: 2em;
}

.row.copyrights-band.mobile-view {
	display: none;
}

/* Business Footer Changes */
.businessSite.navbar {
	padding-top: 7px;
	padding-bottom: 7px;
	background: #fff;
	z-index: 999;
	height: 70px;
}
.businessSite.navbar .navbar-brand {
	padding-left: 6px;
}

.businessSite.navbar .navbar-brand  img{
	width: 261px;
}

.businessSite .navbar-nav .nav-link {
	font-family: 'Fira Sans', Helvetica, Arial, sans-serif;
	color: #111111 !important;
	font-size: 19px;
	line-height: 23px;
	font-weight: 600;
	border-bottom: none !important;
	padding: 16px 0px 16px 25px !important;
	margin-right: 4px !important;
	margin-left: 0 !important;
}
.businessSite .navbar-nav .nav-link:hover {
	transition: 0.2s;
	color: #3395a4 !important;
}

.footer-MenuItems{
	font-family: 'Fira Sans';
	font-size:18px;
	line-height: 1.7em;
    letter-spacing: 0px;
}

#limitedDetailPage {
	h1{
		line-height: 2rem;
		font-family: "Fira Sans",sans-serif;
		font-weight: 600;
		font-style: normal;
		font-size: 1.5rem;
		margin-bottom: 0;
		span{
			flex: 1 1;
			line-height: 120%;
			min-height: 3.4375rem;
			padding-top: .8125rem;
			padding-bottom: .625rem;
		}
		i{
			max-height: 1.75rem;
    		margin-top: -2.6875rem;
		}
	}
	h2{
		margin-top: 2.1875rem;
		margin-bottom: 1.1875rem;
		overflow: hidden;
		font-family: "Fira Sans",sans-serif;
		font-weight: 600;
		font-style: normal;
		font-size: 1.26562rem;
		line-height: 2rem;
	}
	h2:after{
		content: "";
		display: inline-block;
		width: 100%;
		height: 100%;
		margin-right: -100%;
		border-bottom: 2px solid #e5e5e5;
		position: relative;
		top: -3px;
		left: 5px;
	}
}

.noresultText{
	margin-top:4rem;
}

@media (max-width: 767px){
	#limitedDetailPage {
		h1{
			flex-flow: column;
			i {
				margin: 0 auto .375rem 0;
			}
			label{
				margin-right: 0 !important;
			}
			span{
				left: -1.625rem;
				min-width: 100%;
				padding-left: 1.625rem;
				word-break: break-word;
			}
		}
	}
}

@media (max-width: 991px) {
	.businessSite.navbar .navbar-brand img
	{
		width: 251px;
	}
	.businessSite .navbar-nav .nav-link {
		padding-top: 18px !important;
	}
	.businessSite.navbar-light .navbar-toggler-icon {
		color: #4c4c4c !important;
		font-size: 24px;
		font-weight: bold;
	}
	.businessSite .navbar-nav {
		background: #fff;
		margin-top: 0 !important;
		width: 100%;
	}
	.businessSite .navbar-nav .nav-link {
		color: #000 !important;
	}
	.businessSite .navbar-nav .nav-link:hover {
		transition: 0.2s;

		color: #fff !important;
	}
	.businessSite .navbar-nav .login-toolbar
	{
		margin-left: 22px!important;
		margin-bottom: 16px;
	}
	.businessSite .navbar-collapse
	{
		margin-left: -16px;
		margin-right: -16px;
	}
	.row.copyrights-band p
	{
		padding-left: 0;
	}
}
#navAlert .backBtn {
	background: #fff;
	color: #3395a4;
	font-family: 'Oswald', sans-serif;
	font-weight: 600;
	font-size: 18px;
	border-radius: 0;
	border-color: #3395a4;
	height: 42px;
	width: 97px;
	line-height: 14px;
	border-radius: 4px;
}

.signinPopup {
	font-family: 'Fira Sans', Helvetica, Arial, sans-serif;
	border: 8px solid #fff;
	border-radius: 5px;
	width: 350px;
	margin-top: 70px;
	.modal-content {
		padding: 32px;
		background: rgba(0, 98, 113, 1) !important;
		.modal-body {
			padding: 0;
			h3 {
				font-weight: 600;
				font-size: 31px;
				color: #fff;
			}
			.form-group {
				label {
					margin-bottom: 0;
				}
			}
			.form-group,
			.form-check {
				margin-bottom: 1rem;
				margin-top: 1rem;
			}
			label {
				font-family: 'Fira Sans', Helvetica, Arial, sans-serif;
				font-size: 14px;
				color: #fff;
			}
			.form-check-label {
				margin-left: 8px;
				color: #fff;
				font-size: 19.2px;
			}
			input[type='text'],
			input[type='password'] {
				display: block;
				width: 100%;
				height: 48px;
				margin-bottom: 24px;
				padding: 0 12px;
				border-width: 1px;
				border-color: #fff;
				border-style: solid;
				border-radius: 4px;
				background-color: #f5f5f5;
				color: #4c4c4c;
				font-size: 18px;
			}
			.help {
				font-size: 16px;
				font-weight: 600;
				a {
					font-size: 16px;
					font-weight: 600;
					color: #fff !important;
					font-family: 'Fira Sans', Helvetica, Arial, sans-serif;
				}
			}
			.btnWrapper {
				font-weight: 500;
				color: #fff;
				font-size: 16px;
				font-family: 'Fira Sans', Helvetica, Arial, sans-serif;
				button {
					display: inline-block !important;
					background: #3395a4 !important;
					border: none !important;
					width: 151px !important;
					font-size: 19px !important;
					color: #fff !important;
					font-weight: 600;
					margin-right: 15px;
					margin-top: 8px;
					height: 48px;
					margin-bottom: 24px;
					padding: 0 12px;
					border-radius: 4px;
					outline: none;
					&:focus {
						outline: none !important;
						box-shadow: none;
					}
				}
				a {
					font-weight: 500;
					color: #fff !important;
					font-size: 16px;
					font-family: 'Fira Sans', Helvetica, Arial, sans-serif;
				}
			}
			.emailError,
			.errorPassword {
				background: #e43939;
				color: #fff !important;
				padding: 3px 10px 3px 12px;
				margin-top: -24px;
				border-radius: 3px;
				margin-bottom: 0;
				font-weight: 600 !important;
				font-size: 15px !important;
			}
			.apiError {
				color: #fff !important;
				font-weight: 600 !important;
				font-size: 14px !important;
				margin-top: -14px;
				margin-bottom: 10px;
				margin-left: -32px;
				margin-right: -32px;
				background: #e43939;
				padding: 4px 32px 4px 32px;
			}
			.close {
				position: relative;
				right: -58px;
				top: -58px;
				opacity: 1;
				&:focus {
					outline: none;
				}
			}
		}
	}
}



// Agency Landing Page
.agencyLandingWrapper{
	margin-bottom: 0!important;
	padding-top: 0;
	border-bottom: 1px solid rgba(63, 107, 190, .25);
}
.alBannerWrapper{
	width: 100%;
	min-height: 360px;
	background-repeat: no-repeat;
	/* background-size: cover; */
	background-color: rgba(31, 106, 133, 0.75);
	background-position: center center;
	position: relative;
	.alTitleLogo {
		background-color: rgba(63, 63, 63, 0.81);
		height: 120px;
		width: 100%;
		position: absolute;
		bottom: 0;
		left: 0;
		padding-bottom: 20px;
		padding-top: 20px;
		border-bottom: 1px solid #8e8b8b;
		.alBannerWrapper {
			width: 100%;
			min-height: 360px;
			background-repeat: no-repeat;
			/* background-size: cover; */
			background-color: rgba(31, 106, 133, 0.75);
			background-position: center center;
			position: relative;
			
		}
	}
}
.alTitleLogo {
	padding-left: 48px;
	padding-right: 48px;
	.alInnerCol {
	min-height: 80px;
	display: flex;
	align-items: center;
	margin-top: -20px;
	.alLogo {
		display: inline-block;
		margin-right: 20px;
		background: #bcbcbc;
		padding: 5px;
		border: 2px solid #e4e1dd;
		height: 120px;
		max-width: 200px;
		min-width: 110px;
		text-align: center;
			div{
				display: flex;
				align-items: center;
				justify-content: center;
				height: 100%;
				img{
					max-width: 180px;
					max-height: 108px;
				}
			}
		}
	.alName {
			flex: 1;
			h3{
				font-size: 32px;
				line-height: 38px;
				font-weight: 500;
				margin-bottom: 0;
				color: #fff;
			}
		}
	}	
	.poweredBy {
		position: relative;
		right: 0;
		bottom: 25px;
		font-size: 12px;
		color: #c3bdbd;
		text-align: right;
		width: 96%;
	}
}

.alContentWrapper {
    background: #353535;
    padding-top: 32px;
	padding-bottom: 32px;
	.alInnerCol {
    background: #fff;
    padding: 48px;
	min-height: 300px;
	h1 {
		text-transform: none;
		font-size: 36px;
    	line-height: 1.33;
	}
	}
}