.password-strength-meter {
    display: flex;
    align-items:center;
    margin: 5px;
    justify-content: space-between;
  }
  
  .strength-meter {
    position: relative;
    width: 90%;
    height: 2px;
    background-color: #e0e0e0;
    border-radius: 5px;
    margin-bottom: 10px;
    margin-top: 8px;

  }
  
  .strength-meter-fill {
    height: 100%;
    transition: width 0.3s;
  }
  
  .strength-0 {
    width: 0%;
    background-color: red;
  }
  
  .strength-1 {
    width: 20%;
    background-color: red;
  }
  
  .strength-2 {
    width: 40%;
    background-color: orange;
  }
  
  .strength-3 {
    width: 60%;
    background-color: rgb(0, 119, 255);
  }
  
  .strength-4 {
    width: 80%;
    background-color: lightgreen;
  }
  
  .strength-5 {
    width: 100%;
    background-color: green;
  }
  