@import './classgenerator';
/*Static link*/
h1 {
  @include firaSansSemiBold(28.83);
  line-height: calculateRem(48);
  margin-bottom: calculateRem(19);
  a {
    @include firaSansSemiBold(28.83);
    line-height: calculateRem(48);
  }
}

h2 {
  @include firaSansSemiBold(25.63);
  line-height: calculateRem(32);
  margin-bottom: calculateRem(24);
  a {
    @include firaSansSemiBold(25.63);
    line-height: calculateRem(32);
  }
}

h3 {
  @include firaSansSemiBold(22.78);
  line-height: calculateRem(32);
  margin-bottom: calculateRem(26);
  a {
    @include firaSansSemiBold(22.78);
    line-height: calculateRem(32);
  }
}

h4 {
  @include firaSansSemiBold(20.25);
  line-height: calculateRem(32);
  margin-bottom: calculateRem(19);
  a {
    @include firaSansSemiBold(20.25);
    line-height: calculateRem(32);
  }
  // &:before
  // {
  // 	right: 100%;
  // 	margin-right: 15px;
  // }
  // &:before
  // {
  // 	left: 100%;
  // 	margin-left: 15px;
  // }
}

h5 {
  @include firaSansSemiBold(18);
  line-height: calculateRem(32);
  margin-bottom: calculateRem(13);
  a {
    @include firaSansSemiBold(18);
    line-height: calculateRem(32);
  }
}

h6 {
  @include firaSansSemiBold(16);
  margin-bottom: calculateRem(4);
  line-height: calculateRem(32);
  a {
    @include firaSansSemiBold(16);
    margin-bottom: calculateRem(4);
  }
}

p {
  @include sourceSansProRegular(16);
  margin-bottom: calculateRem(20);
  line-height: calculateRem(24);
}

small {
  @include sourceSansProRegular(13);
  b {
    @include sourceSansProBold(13);
  }
}

//Basic fonts
.firaLight {
  @include firaSansLight(16);
}
.firaRegular {
  @include firaSansRegular(16);
}
.firaMedium {
  @include firaSansMedium(16, !important);
  .staticLink {
    @include firaSansMedium(16, !important);
  }
}
.firaSemiBold {
  @include firaSansSemiBold(16);
}
.firaBold {
  @include firaSansBold(16);
}
.sourceLight {
  @include sourceSansProLight(16);
}
.sourceItalic {
  @include sourceSansProItalic(16);
}
.sourceRegular {
  @include sourceSansProRegular(16);
}
.sourceSemiBold {
  @include sourceSansProSemiBold(16);
}
.sourceBold {
  @include sourceSansProBold(16);
}

.helpWrapper {
  h5 {
    margin-bottom: calculateRem(6);
  }
  p {
    @include sourceSansProRegular(18);
    b {
      color: $teal;
      @include sourceSansProSemiBold(16);
    }
  }
  ul {
    margin-bottom: calculateRem(32);
    li {
      @include sourceSansProSemiBold(16);
      line-height: calculateRem(24);
      margin-bottom: calculateRem(8);
      span {
        @include sourceSansProSemiBold(16);
      }
    }
  }
  ol {
    li {
      @include sourceSansProRegular(18);
      line-height: calculateRem(24);
      margin-bottom: calculateRem(16);
      img {
        margin-bottom: calculateRem(24);
      }
    }
  }
}

.listStyle {
  li {
    @include sourceSansProRegular(16);
    line-height: calculateRem(24);
    margin-bottom: calculateRem(8);
  }
}

.listStyleCustom {
  list-style: none;

  li {
    @include sourceSansProRegular(16);
    line-height: calculateRem(24);
    margin-bottom: calculateRem(8);
    // padding-left: 1.3em;

    &:before {
      font-family: 'Material Design Icons';
      font-weight: normal;
      position: relative;
      top: 0;
      left: 0;
      color: $o-black;
      background: none;
      // display: inline-block;
      // margin-left: -1.3em; /* same as padding-left set on li */
      // width: 1.3em;
      margin-left: calculateRem(-20);
    }
  }
  &.arrow {
    li {
      &:before {
        content: '\F0142';
      }
    }
  }
}

// flex
.flex1 {
  flex: 1;
}

// table style
table {
  th {
    padding-right: calculateRem(5);
    padding-left: calculateRem(5);
    @include firaSansSemiBold(16);
    line-height: calculateRem(24);
  }

  td {
    padding-right: calculateRem(5);
    padding-left: calculateRem(5);
    @include sourceSansProRegular(16);
    line-height: calculateRem(24);
    vertical-align: middle;

    .radioControl {
      // margin-top: calculateRem(-16);
      margin-top: calculateRem(-7);
    }

    .inputWrapper,
    .react-select {
      margin: 0;

      .errorMsg,
      label {
        display: none;
      }
    }

    .inputWrapper {
      input {
        @include padding(4, 32, 4, 12);
        height: calculateRem(32);
        line-height: calculateRem(32);
      }

      & ~ .mdi-close-box-outline {
        position: absolute;
        right: calculateRem(15);
        margin-top: calculateRem(-28);
        float: right;
      }
    }

    .react-select {
      div {
        padding-bottom: 0 !important;
        padding-top: 0 !important;

        &:first-child,
        [class*='css-'][class$='-control'] {
          min-height: calculateRem(28) !important;
        }

        [class*='css-'][class$='-control'] {
          line-height: calculateRem(28);
        }

        [class*='css-'][class$='-menu'] {
          top: calculateRem(23);
        }

        [class*='css-'][class$='-MenuList'] {
          // line-height: calculateRem(16);
          @include padding(8, null, 8, null);
        }
      }
    }
    .datePickerWrapper {
      margin-bottom: 0 !important;
      margin-top: 0 !important;
      .errorMsg,
      label {
        display: none;
      }
      .SingleDatePickerInput_clearDate {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
      }
      .DateInput_input,
      .SingleDatePickerInput_calendarIcon {
        padding-top: calculateRem(3) !important;
        padding-bottom: 0 !important;
      }
      .SingleDatePickerInput {
        min-height: calculateRem(34);
      }
    }
  }
}

.tableData {
  th,
  td {
    @include padding(0, 8, 12, 0);
  }

  tr {
    &:last-child {
      th,
      td {
        padding-bottom: calculateRem(8);
      }
    }
  }
}

.tableHData {
  th {
    @include padding(12, 16, 11, 16);
    vertical-align: middle;
    border-top: 0;
  }

  td {
    @include padding(12, 16, 11, 16);
    vertical-align: top;
    @include border(1, 0, 0, 0, solid, $lightGray);
  }
}

.vMiddle {
  th,
  td {
    vertical-align: middle;
  }
}

.vTop {
  th,
  td {
    vertical-align: top;
  }
}

// input placeholder
::-webkit-input-placeholder {
  @include sourceSansProItalic(16);
  // color: $placeholdergray;
}

:-moz-placeholder {
  @include sourceSansProItalic(16);
  // color: $placeholdergray;
}

::-moz-placeholder {
  @include sourceSansProItalic(16);
  // color: $placeholdergray;
}

:-ms-input-placeholder {
  @include sourceSansProItalic(16);
  // color: $placeholdergray;
}

.staticLink {
  color: $teal;
  @include sourceSansProRegular(16);
  cursor: pointer;
}

.staticTxt {
  color: $dark-purple;
  @include sourceSansProSemiBold(18);
}

.tPadding-5 {
  th,
  td {
    @include padding(5, 5, 5, 0);
  }
}

.whiteLink {
  a {
    color: $white50 !important;
    text-decoration: underline;
    &:hover,
    &:visited,
    &:active {
      color: $white50 !important;
      text-decoration: underline;
    }
  }
}

/*circle indicators*/
.successTick,
.manual,
.notSubmit,
.noneDot,
.errorCross {
  background: $green;
  width: calculateRem(18);
  height: calculateRem(18);
  border-radius: 50%;
  text-align: center;
  padding: 0;
  display: inline-block;
  // margin-right: calculateRem(10);
  // margin-bottom: 12px;
  @include margin(0, 10, 12, 0);
  line-height: calculateRem(18);
  color: $white;
  font-size: calculateRem(12);

  &:before {
    font-family: 'Material Design Icons';
    font-weight: normal;
    position: relative;
    top: 0;
    left: 0;
    color: $white;
  }
}

.successTick {
  &:before {
    content: '\F012C';
  }
}

.manual {
  background: $dark-purple;

  &:before {
    content: '\F06BC';
  }
}

.notSubmit {
  background: $red;
  // width: auto;
  // height: auto;
  // border-radius: 50%;
  font-size: calculateRem(16);

  &:before {
    content: '\F05D6';
  }
}

.noneDot {
  background: $yellow;

  &:before {
    content: '\F044A';
  }
}

.errorCross {
  background: $red;

  &:before {
    content: '\F0156';
  }
}

/*Tags*/
#root .tag {
  min-width: calculateRem(114);
  max-width: fit-content;
  height: calculateRem(22);
  @include sourceSansProRegular(12);
  @include padding(null, 16, null, 16);
  text-align: center;
  @include margin(5, 24, null, 24);
  background: $light-yellow;
  @include borderRadius(10, 10, 10, 10);

  &.success {
    background: $green;
    color: $white;
  }

  &.purple {
    background: $dark-purple;
    color: $white;
  }

  &.status {
    background: $light-yellow;
    color: $black;
    @include firaSansSemiBold(14);
    line-height: calculateRem(24);
  }

  &.draft {
    background: $lightGray;
    color: $red;
    @include firaSansSemiBold(14);
    line-height: calculateRem(24);
  }
  &.detail-draft {
    background: $red;
    color: $o-white50;
    @include firaSansSemiBold(14);
    line-height: calculateRem(24);
  }
  &.cancelledbg {
    background: $o-white400;
    color: $o-gray550;
  }
  &.awardedbg {
    background: $dark-green;
    color: $o-white50;
  }
}

/*Splash*/
.splashWrapper {
  background: $teal;
  @include margin(0, -15, 0, -15);
  height: 100vh;
  overflow: auto;
}

.bg-none {
  background: none !important;
}

.w-95 {
  width: 95% !important;
}

.w-48 {
  width: 48% !important;
}

.minH-20 {
  min-height: 300px;
}

.minH-160 {
  min-height: 160px;
}

.tableFixed {
  table-layout: fixed;
  width: 100%;
}

.bidAlert {
  background-color: $lightGray;
  height: calculateRem(35);
  vertical-align: middle;
  color: $black;
  @include sourceSansProBold(16);
  text-align: right;
  @include padding(5, 10, 5, 5);
  margin-bottom: calculateRem(10);

  span {
    @include sourceSansProBold(16);
    color: $red;
    @include padding(null, null, null, 5);
  }
}

.noteImportant {
  background: $lightGray;
  @include padding(16, 16, 16, 16);
  @include margin(16, -16, 16, -16);
  @include sourceSansProRegular(16);
  li {
    @include sourceSansProRegular(16);
  }
}

.reciept-print {
  display: none;
}
.receipt-logo {
  display: none;
}

@media print {
  .receipt-logo {
    display: block;
    width: 40%;
    text-align:center;
    position: absolute;
    top: calculateRem(-85);
    left: 25%;
  }
  .reciept-screen {
    display: none;
  }

  .reciept-print {
    display: block;
  }
  .headerBreadcrumbWrapper{
    display:none;
  }
  .footerWrapper{
    display:none;
  }
}

// Modal popup overright
.modal-xxl {
  max-width: 90%;
}

.modal-dialog {
  .modal-content {
    border-radius: 0;
    border-color: $lightGray;
    
    @include padding(24, 0, 24, 32);

    .modal-header {
      border: none;
      @include padding(null, 24, null, 24);
      @include margin(-25, 0, 0, -33);
      background: $teal;
      color: $white;
      border-radius: 0;

      .modal-title {
        line-height: calculateRem(32);
      }

      .close {
        color: $white;
        outline: none;
        &:hover {
          color: $white;
        }
      }
    }

    .modal-body {
      @include padding(32, 0, 32, 0);

      .braintree-placeholder {
        margin-top: calculateRem(-32);
      }

      .braintree-form__field-group {
        label {
          width: 100%;
        }
      }

      .checkControl {
        line-height: calculateRem(18);
      }

      .innerColIndent {
        h4 {
          overflow: hidden;

          &:after {
            content: '';
            display: inline-block;
            width: 100%;
            height: 100%;
            margin-right: -100%;
            border-bottom: 2px solid $lightGray;
            position: relative;
            top: -3px;
            left: 5px;
          }

          span {
            color: $teal;
            cursor: pointer;
            @include sourceSansProRegular(14);
          }

          a,
          span {
            float: right;
            position: relative;
            z-index: 1;
            background: $white;
            padding-left: 10px;
            font-size: 14px;
            margin-top: 3px;
          }
        }
      }

      table {
        .react-date-picker {
          input {
            padding-bottom: calculateRem(4);
            padding-top: calculateRem(4);
            line-height: calculateRem(24);
          }

          .react-date-picker__wrapper {
            width: 100%;
          }
        }
      }

      .container {
        h4 {
          &.mt-5 {
            margin-top: 0 !important;
          }
        }

        &.noResultWrapper {
          margin-left: 0;
          margin-right: 0;
          p {
            padding-left: calculateRem(16);
          }
        }
      }
    }
  }

  &.selectBroadcast {
    .modal-body {
      .container {
        padding: 0;
        max-width: 100%;
        margin-left: calculateRem(-16);
        margin-right: calculateRem(-16);

        .generateNewList {
          margin-left: 0;
          margin-right: calculateRem(-32);
        }
      }
    }
  }

  &.popUpSpacing {
    .modal-body {
      h4 {
        overflow: hidden;

        &:after {
          content: '';
          display: inline-block;
          width: 100%;
          height: 100%;
          margin-right: -100%;
          border-bottom: 2px solid $lightGray;
          position: relative;
          top: -3px;
          left: 5px;
        }
      }

      .innerColIndent {
        @include padding(null, 32, null, 32);
      }
    }
  }
}

// Line in text middle
.lineHR {
  overflow: hidden;

  &:after {
    content: '';
    display: inline-block;
    width: 100%;
    height: 100%;
    margin-right: -100%;
    border-bottom: calculateRem(2) solid $lightGray;
    position: relative;
    top: calculateRem(-3);
    left: calculateRem(5);
  }

  span {
    position: relative;
    z-index: 1;
    background: $white;
    padding-left: calculateRem(10);
    font-size: calculateRem(14);
    margin-top: calculateRem(3);
    color: $teal;
    cursor: pointer;
  }
}

// clear left,right,both
.clear-left {
  clear: left;
}
.clear-right {
  clear: right;
}
.clear-both {
  clear: both;
}

// padding top and bottom
.paddingBtnSpace {
  padding-top: calculateRem(5) !important;
  padding-bottom: calculateRem(5) !important;
}

// BG & Text helper classes for all colors in _variables.scss file
.bg {
  @include color-modifiers(background-color);
}
.text {
  @include color-modifiers(color);
}

.bg-purple300 {
  &:not(a) {
    a {
      @extend .text-teal50;
      &:hover {
        @extend .text-teal50;
      }
    }
  }
  &:not(i) {
    .staticLink {
      @extend .text-teal50;
      &:hover {
        @extend .text-teal50;
      }
    }
  }
}

// Text color
.textRed {
  color: $red;
}

.textGreen {
  color: $green;
}

.textYellow {
  color: $yellow;
}

.textPurple {
  color: $purple;
}

.textWhite {
  color: $white50;

  a {
    color: $lighter-teal !important;

    &:hover {
      color: $lighter-teal !important;
    }
  }
}

///// Disable link/button
.disabled-link {
  pointer-events: none;
  color: rgb(158, 155, 155) !important;
}

.disabled-bttn {
  background: $gray200;
  color: $gray100;
  cursor: no-drop;
}

// BG color
.bg-purple {
  background: $dark-purple;
  .staticLink {
    color: $lighter-teal;
  }
}

.bg-lightPurple {
  background: $lighter-purple;
}

.bg-sidebarGray {
  background: $o-white200;
}

.bg-lightBlue {
  background: $lighter-teal;
}

.bg-gray {
  background: $gray;
}

// Alert box / Message box
.alertBox {
  @include padding(12, 12, 12, 12);
  margin-top: calculateRem(16);
  color: $white;
  @extend .bg-purple;
  @include boxShadow(2, 3, 8, rgba($placeholdergray, 0.55));
  @include firaSansRegular(12);
  @include borderRadius(4, 4, 4, 4);
  text-align: center;
  i {
    &.underLine {
      font-style: normal;
      cursor: pointer;
    }
  }
  font-size: 1rem;
  span {
    font-size: 1rem !important;
  }
}

// warning or Error box
.warningBox {
  @include padding(20, 20, 20, 20);
  margin-bottom: calculateRem(16);
  margin-top: calculateRem(16);
  @include firaSansRegular(16);
  @include borderRadius(4, 4, 4, 4);
  position: relative;
  & > i {
    position: absolute;
    top: calculateRem(2);
    right: calculateRem(6);
    font-size: calculateRem(18);
    cursor: pointer;
  }
  p {
    @include firaSansRegular(16);
    margin-bottom: 0;
  }
}

.arrowWrapper {
  min-width: calculateRem(200);
  background-color: $teal;
  color: #fff;
  margin-bottom: 0;
  @include padding(9, 40, 8, 67);
  position: relative;
  min-height: calculateRem(55);
  left: calculateRem(-68);
  position: relative;
  top: calculateRem(-26);
  line-height: inherit;
  display: inline-block;
}

.arrowWrapper:before,
.arrowWrapper:after {
  width: calculateRem(32);
  height: 50%;
  position: absolute;
  left: 100%;
  content: '';
}

.arrowWrapper:before {
  top: 0px;
  background: -webkit-linear-gradient(left bottom, $teal 48.5%, rgba(0, 0, 0, 0) 50%);
  background: linear-gradient(to right top, $teal 48.5%, rgba(0, 0, 0, 0) 50%);
}

.arrowWrapper:after {
  top: 50%;
  background: -webkit-linear-gradient(left top, $teal 48.5%, rgba(0, 0, 0, 0) 50%);
  background: linear-gradient(to right bottom, $teal 48.5%, rgba(0, 0, 0, 0) 50%);
}
.arrowWrapperPurple {
  min-width: calculateRem(200);
  background-color: $darker-purple;
  color: #fff;
  margin-bottom: 0;
  @include padding(9, 40, 8, 67);
  position: relative;
  min-height: calculateRem(55);
  left: calculateRem(-68);
  position: relative;
  top: calculateRem(-26);
  line-height: inherit;
  display: inline-block;
}

.arrowWrapperPurple:before,
.arrowWrapperPurple:after {
  width: calculateRem(32);
  height: 50%;
  position: absolute;
  left: 100%;
  content: '';
}

.arrowWrapperPurple:before {
  top: 0px;
  background: -webkit-linear-gradient(left bottom,$darker-purple 48.5%, rgba(0, 0, 0, 0) 50%);
  background: linear-gradient(to right top,$darker-purple 48.5%, rgba(0, 0, 0, 0) 50%);
}

.arrowWrapperPurple:after {
  top: 50%;
  background: -webkit-linear-gradient(left top, $darker-purple 48.5%, rgba(0, 0, 0, 0) 50%);
  background: linear-gradient(to right bottom,$darker-purple 48.5%, rgba(0, 0, 0, 0) 50%);
}

.minWidth_120 {
  min-width: calculateRem(120);
  margin-top:2rem ;
}

// theme colors
.defaultBG {
  background: $coolgray;

  &.text {
    color: $o-black !important;
  }
}

.bidBG {
  background: $dark-yellow;

  &.text {
    color: $o-black !important;
  }
}

.quoteBG {
  background: $teal;

  &.text {
    color: $white50 !important;
  }
}

// No Result
.noResultWrapper {
  background: $white300;
  color: $o-black;
  @include sourceSansProRegular(16);
  i {
    font-size: calculateRem(18);
    color: $darker-purple;
    position: relative;
    top: calculateRem(1);
  }
}

// Cursor type
.cursorPointer {
  cursor: pointer;
}

.cursorNotAllowed {
  cursor: not-allowed;
}

// link underline
.underLine {
  text-decoration: underline;
}

// Toast Style Changes
.Toastify__toast-container {
  z-index: 99999;
}
.Toastify__toast--success {
  background-color: $dark-green !important;
}

.Toastify__toast--error {
  background-color: lighten($dark-red, 5%) !important;
}

// list arrow
// $bg-img: url('./../images/liarrow.png');

.listArrow li {
  // background-image: url('./../images/liarrow.png');
  // background-repeat: no-repeat;
  // background-position: -20px -10px;
  // position: relative;

  // margin-bottom: 15px;
}
.listArrow li:before {
  // content: '';
  // background-image: url('./../images/liarrow.png');
  // background-repeat: no-repeat;
  // // background-position: -20px -10px;
  // position: relative;
}

// remove arrow for number filed
/* Chrome, Safari, Edge, Opera */
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.Notes{
	font-size: 14px;
}