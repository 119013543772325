#purchase-plan {

  .card-row {
    justify-content: center;
    display: flex;
  }

  .purchase-plan-card {
    position: relative;
    flex: 1;
    border-radius: 25px;
    text-align: center;
    min-width:20%;
    max-width:25%;
    border: 1px solid $lighter-coolgray;
    background-color: $white;
    border-radius: 25px;

    &.featured-card {
      min-width:28%;
      max-width:30%;
      margin-top:-1.5rem;
      z-index: 10;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      .header-background, .content-background {

      }
      .content-background {
      }
    }

    .header-background {
      border-radius: 25px 25px 0 0;
      padding-top:1.5rem;
      padding-bottom:.2rem;
    }
    .header-primary {
      background-color: $lighter-coolgray;
    }
    .header-accent {
      background-color: $dark-teal;
      color:$white;
    }
    .content-background{
      padding-top: 2em;
      padding-left: 2em;
      padding-right: 2em;
    }
    .action-button {
      border-radius: 4px;
      padding-left: 1rem;
      padding-right: 1rem;
      margin-bottom:2rem;
    }
    .purchase-plan-price {
      margin: 0 auto;
      height:7rem;
      padding-top:1.75rem;
      .purchase-plan-price-dollars{
        font-size:5rem;
      }
      .purchase-plan-price-message {
        margin-top: 1rem;
        margin-bottom:0;
        margin-left:2.3rem;
        font-size: 0.75rem;
      }
    }
    .subtitle {
      font-weight:$bold;
      font-size:1.2rem;
      padding-bottom:.5rem;
    }
    ul {
      text-align: left;
      padding-left: 1rem;
    }
  }
}

@media (min-width:320px) and (max-width: 425px) {

  #purchase-plan {

    .colWrapper {
      padding: 2rem;
    }
    .purchase-plan-card {
      min-width: 99%;

      &.featured-card {
        min-width:99%;
        margin-top:1rem;
        margin-bottom: 1rem;
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 1023px){

  #purchase-plan {
    .purchase-plan-card {

      min-width:25%;
      max-width:30%;

      &.featured-card {
        max-width:33%;
      }
      .header-background {
        padding-top:1rem;
        h2 {
          font-size: 1rem;
          margin-bottom:.75rem;
        }
      }
      .content-background {
        padding: 1.5rem;
      }
      .purchase-plan-price {

        padding-top:1rem;
        padding-bottom:4.75rem;
        height:4rem;

        .purchase-plan-price-dollars{
          font-size:4rem;
        }
        .purchase-plan-price-message {
          margin-top: 0rem;
          margin-bottom:0;
          margin-left:1.9rem;
          font-size: 0.6rem;
        }
      }

      .bttn-accent, .bttn-primary {
        min-width:5rem;
        font-size:.85rem;
        padding-top:1rem;
      }
      .subtitle {
        font-size:1rem;
        line-height:1.3rem;
      }
    }
  }
}
