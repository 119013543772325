// Change default bootstrap styles
$container-max-widths: (
	//md: 768px,
		// lg: 960px,
		xl: 1600px //xl: 1140px
) !default;
$grid-gutter-width: 32px !default;
// $mdi-font-path: '~@mdi/font/fonts';

@import '~bootstrap/scss/bootstrap.scss';
// @import '~@mdi/font/scss/materialdesignicons.scss';
@import './utils/variables.scss';
@import './utils/mixins.scss';
@import './utils/helper.scss';
@import './ie.scss';
@import './print.scss';
@import '../scss/theme/themes.scss';

.anonymousAgency {
	header {
		background: $teal;
		.navbar {
			min-height: calculateRem(55);
			svg {
				width: calculateRem(180);
				fill: $white50;
			}
		}
	}
}
