@import '../utils/variables.scss';
@import '../utils/mixins.scss';

.headerBreadcrumbWrapper {
	min-height: calculateRem(123);
	margin-bottom: calculateRem(15);
	margin-top: calculateRem(0);
	// transition: all 750ms cubic-bezier(0.55, 0, 0.1, 1);
	position: fixed;
	width: 100%;
	z-index: 111;
	& + .container {
		padding-top: calculateRem(140);
	}
	&.ops {
		& + .container {
			padding-top: calculateRem(170);
		}
	}
	header {
		min-height: calculateRem(74);

		nav {
			&.navbar {
				background: $teal;
				min-height: calculateRem(70);
				// padding: 0 15px;
				@include padding(0, 15, 0, 15);
			}

			&.navbar-light {
				.navbar-brand {
					img {
						width: calculateRem(180);
					}

					svg {
						width: calculateRem(180);
						fill: $teal;
					}
				}

				.navbar-nav {
					margin-left: 1%;
					.nav-item {
						text-align: center;

						&.contract {
							a {
								padding-top: calculateRem(18);
								line-height: calculateRem(14);
								span {
									margin-left: 2.2%;
								}
							}
						}
					}
					.nav-link {
						// @include firaSansSemiBold(19);
						align-items: center;
						color: $white;
						display: flex;
						@include padding(8, 15, 0, 15);
						// padding: 22px 15px 18px 15px;
						min-height: calculateRem(70);
						line-height: calculateRem(26);
						margin-right: calculateRem(0);
						@include border(0, 0, 8, 0, solid, transparent);
						max-width: calculateRem(160);

						// min-height: calculateRem(74);
						@include firaSansSemiBold(17);

						&:hover
						// &:focus
						{
							background: $darkTeal;
							color: $white;

							i {
								background: $white;
								color: $darkTeal;
								display: none;
							}
						}

						i {
							font-style: $normal;
							background: $darkTeal;
							font-size: calculateRem(14);
							border-radius: 50%;
							// padding: 4px 5px;
							@include padding(4, 5, 4, 5);
							margin-left: calculateRem(4);
							text-align: center;
							position: relative;
							top: calculateRem(-2);
							left: calculateRem(2);
							display: none;
						}
						span {
							position: absolute;
							// top: calculateRem(1);
							/* left: auto; */
							display: block;
							// margin-left: scalculateRem(12);
							@include sourceSansProRegular(12);
							top: calculateRem(4);
						}

						&.active {
							background: $darkTeal;
							color: $white;

							i {
								background: $white;
								color: $darkTeal;
								display: none;
							}
						}
					}
				}
			}

			.dropdown {
				.btn-link {
					color: $white;
					@include firaSansSemiBold(19);
					text-decoration: none;

					&.dropdown-toggle {
						&:after {
							margin-left: calculateRem(10);
							vertical-align: calculateRem(1);
						}
					}
				}

				.dropdown-menu {
					left: auto;
					right: 0;
					top: calculateRem(53);
					min-width: calculateRem(200);
					border-radius: 0;

					.dropdown-item {
						line-height: calculateRem(20.1);
						@include margin(9, 0, 9, 0);
						@include padding(7, null, 7, null);
						@include sourceSansProRegular(20);

						i {
							color: $teal;
							font-size: calculateRem(22);
							margin-right: calculateRem(5);
							position: relative;
							top: calculateRem(1);
							left: 0;
						}

						&:active {
							background-color: rgba(51, 149, 164, 0.24);
							color: $black;
						}

						& ~ hr {
							@include margin(5, 0, 5, 0);
						}
					}

					button {
						@include sourceSansProRegular(16);
						cursor: pointer;
					}
				}
			}
		}
	}

	&.fixed-nav {
		position: fixed;
		top: 0;
		width: 100%;
		/* margin-bottom: 80px; */
		z-index: 111;
		@include boxShadow(0, 2, 13, $black);
		min-height: calculateRem(70);
		transition-property: opacity, left;
		transition-duration: 2s, 4s;

		header {
			min-height: calculateRem(44);

			nav {
				&.navbar {
					min-height: calculateRem(40);
					margin-top: calculateRem(0);

					.navbar-brand {
						@include padding(3, null, 3, null);

						img {
							width: calculateRem(140);
						}

						svg {
							width: calculateRem(140);
						}
					}
				}

				&.navbar-light {
					.navbar-nav {
						margin-left: 9.5%;
						.nav-item {
							text-align: center;
							&.contract {
								a {
									line-height: calculateRem(16);
									span {
										margin-left: 2.2%;
									}
								}
							}
						}
						.nav-link {
							margin-top: calculateRem(0);

							@include firaSansSemiBold(16);
							color: $white;
							@include padding(15, 15, 0, 15);
							// padding: 22px 15px 18px 15px;
							// min-height: calculateRem(40);
							line-height: calculateRem(29);
							margin-right: calculateRem(0);
							border-bottom: calculateRem(5) solid transparent;

							min-height: calculateRem(60);
							span {
								top: calculateRem(-1);
							}
						}
					}
				}

				.dropdown {
					.btn-link {
						@include firaSansSemiBold(16);
					}
					&.themeDD {
						.dropdown-menu {
							&[style] {
								top: calculateRem(39) !important;
							}
						}
					}

					.dropdown-menu {
						// top: calculateRem(36);
						&[style] {
							top: calculateRem(34) !important;
						}
					}
				}
			}
		}

		.breadcrumbWrapper {
			@include padding(2, null, 2, null);
			margin-bottom: 0;
			min-height: calculateRem(34);

			.breadcrumb {
				@include padding(2, 0, 0, 0);
				@include sourceSansProSemiBold(13);
				margin-top: calculateRem(0);

				&.pageControl {
					margin-top: calculateRem(0);
					margin-bottom: calculateRem(-2);
					top: 0;

					li {
						@include borderRadius(2, 2, 2, 2);
						@include padding(4, null, 4, null);

						span {
							@include sourceSansProSemiBold(14);
							line-height: calculateRem(12);

							i {
								font-size: calculateRem(17.5);
								top: 0;
							}
						}

						a {
							@include sourceSansProSemiBold(12);
							line-height: calculateRem(16);

							i {
								font-size: calculateRem(17.5);
								top: 0;
							}
						}
						.dropdown {
							.dropdown-menu {
								border-radius: 0;
								margin-left: calculateRem(-71) !important;
								top: calculateRem(3) !important;
								.dropdown-item {
									font-size: calculateRem(15);
								}
							}
						}
					}
				}

				.breadcrumb-item {
					&.active,
					a {
						@include sourceSansProSemiBold(13);
						line-height: calculateRem(27);
					}
					&::before {
						top: calculateRem(5);
					}
				}
			}
		}
	}
}

.opsAlertWrapper {
	min-height: 30px;
	color: $white50;
	text-align: center;
	line-height: calculateRem(30);
	@include firaSansSemiBold(16);
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 111;
	~ .headerBreadcrumbWrapper {
		margin-top: 30px;
		&.fixed-nav {
			box-shadow: none;
		}
	}
}

.eunaBannerWrapper {
	min-height: 30px;
	color: $white50;
	text-align: center;
	line-height: calculateRem(30);
	@include firaSansSemiBold(16);
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 111;
	~ .headerBreadcrumbWrapper {
		margin-top: 30px;
		&.fixed-nav {
			box-shadow: none;
		}
	}
}
.bannerCurser{
	cursor: pointer;
}

.dropDownWrapper {
	&.dropdown {
		& > .btn-secondary {
			background: none !important;
			border: none !important;
			padding: 0;
			outline: none !important;
			&:active {
				background: none;
				border: none;
				outline: none !important;
				box-shadow: none;
			}
			&:not(:disabled),
			&:not(:disabled):active:focus {
				box-shadow: none;
			}
		}
		&.themeDD {
			margin-top: calculateRem(5);

			.dropdown-menu {
				&[style] {
					top: calculateRem(51) !important;
				}
			}
		}
		.dropdown-menu {
			// border: none;
			outline: none;
			&[style] {
				// top: calculateRem(10) !important;
				margin-right: calculateRem(0) !important;
				top: calculateRem(47) !important;
				left: auto !important;
				transform: none !important;
			}
		}
	}
}
