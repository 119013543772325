@import '../scss/utils/variables';
@import '../scss/utils/functions';
@import './utils/mixins.scss';
@import './utils/helper.scss';

.subNationalBtn{
    small{
        text-align: right;
        right: calculateRem(8);
        top: calculateRem(6);
        i{
          margin-left: calculateRem(6);
          font-size: calculateRem(16);
          line-height: calculateRem(8);
        }
    }
}
.subProductWrapper
{
    .subStateList
    {
        &.selectedProduct{
            &>div{

                background: $teal;
                .countyName {
                    color: $white50;
                }
            }
        }
        &>div{
            background:$white50;
            padding: 0px;
            @include margin(0,16,20,8);
            @include borderRadius(4,4,4,4);
            box-shadow: 0px 3px 6px rgba(00, 00, 00, 0.16);
            min-height: calculateRem(48);
            @include padding(8,8,8,8);
            @include border(1,1,1,1,solid,$coolgray);
            cursor: pointer;
            &>.countyName{
               @include firaSansMedium(15);
               color: $gray300;
               cursor: pointer;
           }
            &>.checkControl {
                top:calculateRem(-8);
                margin: 0;
                .checkmark,.indeterminate{
                    right: 0;
                    left: auto;
                }
                
            }
        }
    }
}

.countyListWrapper{
    .countyList{
        overflow: auto;
        max-height: calculateRem(400);
        .countyNamePrie{
            display: flex;
            margin-bottom: calculateRem(12);
            &>.checkControl{
                flex: 1;
                margin-bottom: 0;
            }
            &>span{
                width: calculateRem(100);
                margin-left: calculateRem(16);
                margin-right: calculateRem(16);
            }
            &>i{
                width: calculateRem(100);
            }
        }
    }
    .addAllCount{
        .addToCart{
            cursor: pointer;
            background: $teal;
            @include padding(10,10,10,10);
            p{
                @include firaSansSemiBold(14);
                color: $white50;
                margin-bottom: calculateRem(16);
                i{
                    color: $white50;
                    float: right;
                    clear: left;
                    font-size: calculateRem(20);
                }
            }
            small{
                color: $white50;
            }
            h5{
                color: $white50;
                margin-bottom: 0;
            }
        }
    }
}

.prodAgencyList
{
    max-height: calc(100vh - 240px);
    overflow: auto;
    min-height: calculateRem(200);
    li{
        @include border(0,0,1,0,solid,$white600);
        margin-bottom: calculateRem(14);
        @include padding(0,10,10,10);
    }
}

.cartWrapper
{
    max-height: calculateRem(400);
    overflow: auto;
    ul
    {
        li
        {
            span{
                width: calculateRem(100);
                margin-left: calculateRem(12);
                margin-right: calculateRem(12);
                text-align: right;
            }
            
        }
    }
}

.cartTotal
{
    @include firaSansSemiBold(16);
    ul{
        li{
            &:first-Child{
                width:calculateRem(120);
                margin-right: calculateRem(4);
            }
            &:last-child
            {
                flex:1;
                text-align: right;
            }
        }
    }
    
}

.confirmSubWrapper
{
    .confirmProdList{
        max-height: calc(100vh - 340px);
        min-height: calculateRem(200);
        overflow: auto;
        ul{
            padding-left: calculateRem(36);
            li{
                margin-bottom: calculateRem(16);
                line-height: calculateRem(20);
                ul{
                    padding-left: calculateRem(32);
                    li{
                        margin-bottom: calculateRem(8);
                        line-height: calculateRem(20);
                    }
                }
            }
        }
    }
}

.subReviewBackBtn
{
    position: relative;
    top:calculateRem(-27);
}

.subPrintWrapper
{
    ul{
        margin-bottom: calculateRem(20);
        li{
            @include border(0,0,1,0,solid,$white500);
            padding-bottom: calculateRem(8);
            padding-top: calculateRem(8);
            &:last-child{
                border: none;
            }
            ul{
                li{
                    @include border(0,0,1,0,solid,$white500);
                    &:last-child{
                        @include border(0,0,1,0,solid,$white500);
                    }
                }
            }
        }
    }
}
.auto-renew{
  margin-top: 5px;
  position: absolute;
    .checkControl {
        .checkmark{
            @include border(2, 2, 2, 2, solid, $teal);
            &:after {
                left: calculateRem(4);
                }
            }
    }
}