@import '../scss/utils/variables';
@import '../scss/utils/functions';

@mixin media($minWidth: null, $maxWidth: null) {
	@if ($minWidth != '' and $maxWidth != '') {
		@media (min-width: #{$minWidth}px) and	(max-width: #{$maxWidth}px) {
			@content;
		}
	} @else if ($minWidth == '' and $maxWidth != '') {
		@media (max-width: #{$maxWidth}px) {
			@content;
		}
	} @else if($minWidth != '' and $maxWidth == '') {
		@media (min-width: #{$minWidth}px) {
			@content;
		}
	}
}

@include media(568, 992) {
	.headerBreadcrumbWrapper {
		header {
			nav {
				&.navbar {
					justify-content: unset;
					.navbar-brand {
						margin-right: auto;
						order: 1;
						svg {
							width: calculateRem(160);
						}
					}
					.dropdown {
						order: 6;
						.btn-link {
							width: calculateRem(180);
							text-overflow: ellipsis;
							white-space: nowrap;
							overflow: hidden;
						}
					}
					.themeDD {
						&.dropdown {
							order: 3;
							margin-right: calculateRem(20);
							// position: absolute;
							// right: calculateRem(200);
						}
					}
					.helpLink {
						order: 4;
					}
					.accountLink {
						order: 5;
					}
				}
				&.navbar-light {
					.navbar-toggler {
						order: 2;
						margin-right: calculateRem(12);
						border: none;
						outline: none;
						// position: absolute;
						// right: calculateRem(245);
					}
				}
				.navbar-collapse {
					order: 7;
					.navbar-nav {
						width: 100%;
						margin-left: 0;
						.nav-item {
							text-align: left;
							&.contract {
								a {
									padding-top: calculateRem(24);
								}
							}
						}
						.nav-link {
							max-width: 100%;
							span {
								position: relative;
								top: 0;
							}
						}
					}
				}
			}
		}

		.breadcrumbWrapper {
			margin-top: calculateRem(-4);
		}
	}
	.fixed-nav {
		&.navbar-light {
			.navbar-nav {
				margin-left: 0;
			}
		}
	}
}

@include media(992, 1250) {
	.headerBreadcrumbWrapper {
		header {
			nav {
				&.navbar {
					.navbar-brand {
						svg {
							width: calculateRem(168);
						}
					}
				}
				&.navbar-light {
					.navbar-nav {
						margin-left: 1% !important;
						.nav-link {
							font-size: calculateRem(16);
							max-width: calculateRem(115);
							word-wrap: break-word;
						}
					}
				}
				.dropdown {
					.btn-link {
						font-size: calculateRem(16);
						max-width: calculateRem(115);
						overflow: hidden;
						text-overflow: ellipsis;
					}
				}
			}
		}
	}
	.fixed-nav {
		&.navbar-light {
			.navbar-nav {
				margin-left: 1% !important;
			}
		}
	}

	// bid search text wrap
	.searchTxtResp {
		&.comboWrapper,
		&.inputWrapper {
			& > div {
				label {
					width: 70%;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}
			}
		}
	}
}

@include media(992, 1330) {
	.nav {
		&.nav-tabs {
			display: flex;
			flex-wrap: nowrap;
			@include padding(0, 8, 0, 8);
			padding-left: calculateRem(8) !important;
			.nav-item {
				display: flex;
				// min-height: calculateRem(45);
				.nav-link {
					line-height: calculateRem(16);
					// max-height: calculateRem(60);
					// height: auto;
					display: flex;
					align-self: auto;
					align-items: center;
				}
			}
		}
	}
}
@include media('', 991) {
	.bttn-primary {
		&.ebidCamp {
			width: 100%;
			margin-bottom: calculateRem(12);
		}
	}
	.tableFixed{
		th[style],td[style]{width: auto!important;}

	}
}

@include media(992, 1160) {
	.headerBreadcrumbWrapper {
		header {
			nav {
				&.navbar-light {
					.navbar-nav {
						.nav-item {
							// text-align: left;
							&.contract {
								a {
									span {
										margin-left: 1.6%;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
@include media('', 1024) {
	.business-header{
		.container-fluid{
			padding-left: 1%!important;
			padding-right: 2%!important;
		}
	} 
	.business-footer{
		padding-top: calculateRem(24)!important;
		.widget{
			&.widget_media_image {
				float: none;
				img{
					margin-top: 0;
				}
			}
			&+section{
				h5
				{
					&::before
					{
						top: calculateRem(96);
					}
				}
			}
		}
	}
}
@include media('', 767) {
	.headerBreadcrumbWrapper{
		&+.container{
			padding-top: calculateRem(150);
		}
		&.ops {
			& + .container {
				padding-top: calculateRem(180);
			}
		}
		header {
			nav
			{
				&.navbar{
					align-items: baseline;
				}
			}
		}
	}
	.headerFilter {
		flex-wrap: wrap;
		b {
			flex: 1 1 100%;
		}
		.sortWrapper {
			margin-bottom: calculateRem(16);
		}
	}
	.listGroupWrapper {
		h5 {
			flex-wrap: wrap;
			span {
				margin-left: 0 !important;
				margin-top: calculateRem(8);
				margin-bottom: calculateRem(8);
			}
			a,
			span,
			i {
				flex: 1 auto !important;
			}
		}
		ul {
			flex-wrap: wrap;
			li {
				flex-basis: auto !important;
			}
		}
	}
	footer {
		.pagingWrapper {
			flex-flow: column;
			span {
				margin-top: calculateRem(16);
				margin-left: auto;
				margin-right: auto;
			}
		}
	}

	.breadcrumbWrapper{
		.breadcrumb{
			&[style]
			{
				width: 100%!important;
			}
			.breadcrumb-item
			{
				&.active
				{
					&.textWrapp{
						// max-width: ;
					}
				}
			}
		} 
	} 

	.detailViewWrapper{
		.nav-tabs{
			padding-left: 0!important;
		}
		.tableFixed {
			table-layout: auto;
		}
		.tab-content
		{
			padding-left: calculateRem(16);
			padding-right: calculateRem(16);
			.innerColIndent
			{
				padding-left: calculateRem(16);
				padding-right: calculateRem(16);
				.tableData 
				{
					th
					{
						padding-right: calculateRem(16);
						padding-bottom: calculateRem(22);
						&[style]
						{
							width: auto!important;
						}
					}
				}
			}
		h3
		{
			&.d-flex {
				flex-flow: column;
					label{
						margin-right: 0!important;
						span
						{
							&.arrowWrapper
							{
								left: calculateRem(-26);
								min-width: 100%;
								padding-left: calculateRem(26);
								word-break: break-word;
							}
						}
					}
					
					i{
						margin-top: 0;
						margin-right: auto;
						margin-left: 0;
						margin-bottom: calculateRem(6);
					}
				}
		}
	}
	}
	.businessSite{
		&.navbar{
			.container-fluid
			{
				padding-left: 1%;
    			padding-right: 2%;
			}
		}
	}
	.agencyLandingWrapper{
		.alBannerWrapper
		{
			min-height: 440px;
			max-height: 500px;
			.alTitleLogo
			{
				padding-left: calculateRem(0);
				padding-right: calculateRem(0);
				max-height: 320px;
				height: auto;
				word-break:break-all;
				.alInnerCol
				{
					display: block;
					.alLogo
					{
						display: block;
					}
					.alName{
						h3
						{
							font-size: 26px;
							line-height: 32px;
							margin-top: 8px;
							word-break: break-word;
						}
					} 
				}
				.poweredBy
				{
					bottom: -12px;
				}
			}
		}
		.listGroupWrapper 
		{
			h5
			{
				display: block;
				a{
					clear: both;
					&.text-truncate
					{
						white-space: normal;
						display: block;
					}
				}
				span{
					display: block;
					float: left;
				}
			}
		}
		
	.alContentWrapper {
		.alInnerCol
		{
			padding-left: calculateRem(16)!important;
			padding-right: calculateRem(16)!important;
		}
	}
}
}


@include media('', 528)
{
	.businessSite.navbar .navbar-brand  img{
		width: 200px!important;
	}
	.headerBreadcrumbWrapper{
		&+.container{
			padding-top: calculateRem(165);
		}
		&.ops {
			& + .container {
				padding-top: calculateRem(195);
			}
		}
		header {
				nav
				{
					&.navbar{
						min-height: calculateRem(90);
						&.navbar-light {
							.navbar-nav{
								margin-left: 0;
								width: 100%;
								.nav-link{
									max-width: 100%;
									text-align: left;
							}
						}
					}
				}
			}
		}
		.dropDownWrapper{
			&.dropdown
			{
				.dropdown-menu
				{
					&[style]{
						width:calculateRem(245);
						top: calculateRem(30) !important;
						left: 0 !important;
					}
				}
			}
		} 
		.breadcrumb
		{
			flex-wrap: nowrap;
		}


	}
		// buttons
		.bttn-primary{
			height: auto!important;
			min-height: calculateRem(35);
			&.callAction
			{
				margin-bottom: calculateRem(16);
			}
		}
		// WP header
		.businessSite{
			&.navbar-light
			{
				.navbar-toggler
				{
					// position: absolute;
					// right: calculateRem(86);
					.navbar-toggler-icon{
						font-size: calculateRem(18)!important;
					}
				}
			}
			&.navbar{
				.container-fluid
				{
					// padding-left: calculateRem(8)!important;
					// padding-right: calculateRem(8)!important;
					.navbar-brand
					{
						img{
							&[style]
							{
								width: 100px;
							}
						}
					}
				}
			}
		}
		
}