// Color definitions, background and border color classes live here.

// Teal 
// est. 3/2021; all others to deprecate
$lighter-teal:  #6ae9fc;
$light-teal:    #50bfcf;
$teal:          #3395a4;       // this is "brand teal"
$dark-teal:     #1f6a85;
$darker-teal:   #044266;

// this color is not in our styleguide but is all over the product
// so it stays for now.
$darkTeal: #005560;

// Cool grey (light to dark)
$lighter-coolgray:  #e1eced;
$light-coolgray:    #d2dfe1;
$coolgray:          #c3d4d6;
$dark-coolgray:     #93a2ad;
$darker-coolgray:   #627085;

// Green light to dark
$lighter-green: #c2e375; // don't use this color. it's horrible.
$light-green:   #a5d574;
$green:         #8ac675;
$dark-green:    #609b58;
$darker-green:  #366f3c;

// Yellow light to dark
$lighter-yellow:  #fff0b1;
$light-yellow:    #ffd67d;
$yellow:          #ffb84a;
$dark-yellow:     #e39011;
$darker-yellow:   #c16f00;

// Orange light to dark
$lighter-orange:  #fdcda9;
$light-orange:    #f8af79;
$orange:          #eb8954;
$dark-orange:     #c36531;
$darker-orange:   #914012;

// Red light to dark
$lighter-red:     #fab2b2;
$light-red:       #e88686;
$red: #dc3826;    // this color's all over the product but is wrong; leaving it for now
$new-red:         #c76060;
$dark-red:        #a23d3d;
$darker-red:      #5c1515;

// Purple light to dark
$lighter-purple:  #ddcbff;
$light-purple:    #af93da;
$purple:          #7e5cb5;
$dark-purple:     #593e87;
$darker-purple:   #372458;

/* ---- everything after this is old until you get to bg & border */

//white to black(light to dark)
$o-white50: #ffffff;
$white: $o-white50;

$o-white100: #fafafa;
$o-white200: #f5f5f5;
$o-white300: #eaeaea;
$o-white400: #e0e0e0;
$o-white500: #dddddd;
$o-white600: #cccccc;

$white50: $o-white50;
$white100: $o-white100;
$white200: $o-white200;
$white300: $o-white300;
$white400: $o-white400;
$white500: $o-white500;
$white600: $o-white600;

$o-gray50: #b3b3b3;
$gray: $o-gray50; //these are sprinkled all over and need to be replaced carefully

$o-gray100: #7a7a7a;
$o-gray200: #666666;
$o-gray300: #4f4f4f;
$o-gray400: #3f3f3f;
$o-gray500: #393d3d;
$o-gray550: #323232;
$o-gray600: #343434;
$o-gray700: #2e3436;
$o-gray800: #2d3333;
$o-gray900: #272b2b;
$o-grayD100: #141818;

$o-black: #111111;
$black: $o-black;

$gray50: $o-gray50;
$gray100: $o-gray100;
$gray200: $o-gray200;
$gray300: $o-gray300;
$gray400: $o-gray400;
$gray500: $o-gray500;
$gray600: $o-gray600;
$gray700: $o-gray700;
$gray800: $o-gray800;
$gray900: $o-gray900;
$grayD100: $o-grayD100;

/*---------------------------*/
/* to deprecate 
/*---------------------------*/

/*Grayscale - Object Colors*/
$o-placeholdergray: #595959;
$placeholdergray: $o-placeholdergray;

$o-lightGray: #e5e5e5;
$lightGray: $o-lightGray;

/*Grayscale - Background Colors*/
$o-borderGray: #c6c6c6;
$middleGray: #4d4d4d;

/*---------------------------*/
/* BACKGROUND CLASSES */
/* background color classes -- 
/* use these to set bgs without having to write more css */
/*---------------------------*/

.bg-lighter-yellow {
  background-color:$lighter-yellow!important;
}

.bg-light-yellow {
  background-color:$light-yellow!important;
}

.bg-yellow {
  background-color:$yellow!important; 
}

.bg-dark-yellow {
  background-color:$dark-yellow!important;
}

.bg-darker-yellow {
  background-color:$darker-yellow!important;
}

.bg-light-green {
  background-color:$light-green!important;
}

.bg-green {
  background-color:$green!important; 
}

.bg-dark-green {
  background-color:$dark-green!important;
}

.bg-darker-green {
  background-color:$darker-green!important;
}

.bg-lighter-red {
  background-color:$lighter-red!important;
}

.bg-light-red {
  background-color:$light-red!important;
}

.bg-red {
  background-color:$red!important;
}

.bg-dark-red {
  background-color:$dark-red!important;
}

.bg-darker-red {
  background-color:$darker-red!important;
}

.bg-white {
  background-color:$white!important;
}


/* BORDER CLASSES */ 
.border-light-green {
  border: 1px solid $light-green;
}

.border-green {
  border: 1px solid $green;
}

.border-dark-green {
  border: 1px solid $dark-green;
}

.border-darker-green {
  border: 1px solid $darker-green;
}