@import '../utils/variables.scss';
@import '../utils/mixins.scss';

footer {
	// position: fixed;
	// bottom: 0;
	// left: auto;
	background: #fff;
	width: 100%;
	z-index: 111;
	// box-shadow: 0px -5px 6px $gray;
	// @include boxShadow(0, -5, 6, $gray);
	margin-left: calculateRem(0) !important;
	margin-right: calculateRem(0) !important;
	.bttn {
		&.small {
			margin-top: calculateRem(22);
		}
	}
}

.pagingWrapper {
	// box-shadow: 0px -5px 6px $gray;
	// padding: 12px 0;
	@include padding(12, 0, 12, 0);
	// margin: 15px;
	text-align: center;
	ul {
		flex: 1;
	}
	ul,
	li {
		list-style: none;
		margin: 0;
		padding: 0;
	}
	li {
		display: inline-block;
		// margin: 0px 8px;
		@include margin(0, 4, 0, 4);
		// padding: 5px;
		@include padding(5, 5, 5, 5);
		background: transparent;
		color: $black;
		@include firaSansSemiBold(14);
		width: calculateRem(28);
		height: calculateRem(28);
		line-height: calculateRem(19);
		&.active,
		&:hover {
			background: $teal;
			border-radius: 50%;
			color: $white;
			cursor: pointer;
		}
	}
	span {
		@include sourceSansProRegular(14);
		width: calculateRem(160);
		// padding: 0px 10px;
		@include padding(4, 10, 0, 10);
	}
}
.pagingNote {
	@include sourceSansProRegular(14);
}
