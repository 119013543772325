@import 'variables.scss';
@import 'functions.scss';

@mixin oswaldLight($size, $ffI: null, $fsI: null) {
	font-family: $oswald $ffI;
	font-weight: $light;
	font-style: $normal;
	font-size: calculateRem($size) $fsI;
}
@mixin oswaldRegular($size, $ffI: null, $fsI: null) {
	font-family: $oswald $ffI;
	font-weight: $regular;
	font-style: $normal;
	font-size: calculateRem($size) $fsI;
}
@mixin oswaldMedium($size, $ffI: null, $fsI: null) {
	font-family: $oswald $ffI;
	font-weight: $medium;
	font-style: $normal;
	font-size: calculateRem($size) $fsI;
}
@mixin oswaldSemiBold($size, $ffI: null, $fsI: null) {
	font-family: $oswald $ffI;
	font-weight: $semiBold;
	font-style: $normal;
	font-size: calculateRem($size) $fsI;
}
@mixin oswaldBold($size, $ffI: null, $fsI: null) {
	font-family: $oswald $ffI;
	font-weight: $bold;
	font-style: $normal;
	font-size: calculateRem($size) $fsI;
}
@mixin merriLight($size, $ffI: null, $fsI: null) {
	font-family: $merriweather $ffI;
	font-weight: $light;
	font-style: $normal;
	font-size: calculateRem($size) $fsI;
}
@mixin merriItalic($size, $ffI: null, $fsI: null) {
	font-family: $merriweather $ffI;
	font-weight: $regular;
	font-style: $fsItalic;
	font-size: calculateRem($size) $fsI;
}
@mixin merriRegular($size, $ffI: null, $fsI: null) {
	font-family: $merriweather $ffI;
	font-weight: $regular;
	font-style: $normal;
	font-size: calculateRem($size) $fsI;
}
@mixin merriBold($size, $ffI: null, $fsI: null) {
	font-family: $merriweather $ffI;
	font-weight: $bold;
	font-style: $normal;
	font-size: calculateRem($size) $fsI;
}

@mixin firaSansLight($size, $ffI: null, $fsI: null) {
	font-family: $firaSans $ffI;
	font-weight: $light;
	font-style: $normal;
	font-size: calculateRem($size) $fsI;
}
@mixin firaSansRegular($size, $ffI: null, $fsI: null) {
	font-family: $firaSans $ffI;
	font-weight: $regular;
	font-style: $normal;
	font-size: calculateRem($size) $fsI;
}
@mixin firaSansMedium($size, $ffI: null, $fsI: null) {
	font-family: $firaSans $ffI;
	font-weight: $medium;
	font-style: $normal;
	font-size: calculateRem($size) $fsI;
}
@mixin firaSansSemiBold($size, $ffI: null, $fsI: null) {
	font-family: $firaSans $ffI;
	font-weight: $semiBold;
	font-style: $normal;
	font-size: calculateRem($size) $fsI;
}
@mixin firaSansBold($size, $ffI: null, $fsI: null) {
	font-family: $firaSans $ffI;
	font-weight: $bold;
	font-style: $normal;
	font-size: calculateRem($size) $fsI;
}
@mixin sourceSansProLight($size, $ffI: null, $fsI: null) {
	font-family: $sourceSansPro $ffI;
	font-weight: $light;
	font-style: $normal;
	font-size: calculateRem($size) $fsI;
}
@mixin sourceSansProItalic($size, $ffI: null, $fsI: null) {
	font-family: $sourceSansPro $ffI;
	font-weight: $regular;
	font-style: $italic;
	font-size: calculateRem($size) $fsI;
}
@mixin sourceSansProRegular($size, $ffI: null, $fsI: null) {
	font-family: $sourceSansPro $ffI;
	font-weight: $regular;
	font-style: $normal;
	font-size: calculateRem($size) $fsI;
}
@mixin sourceSansProSemiBold($size, $ffI: null, $fsI: null) {
	font-family: $sourceSansPro $ffI;
	font-weight: $semiBold;
	font-style: $normal;
	font-size: calculateRem($size) $fsI;
}
@mixin sourceSansProBold($size, $ffI: null, $fsI: null) {
	font-family: $sourceSansPro $ffI;
	font-weight: $bold;
	font-style: $normal;
	font-size: calculateRem($size) $fsI;
}

//Padding mixin
@mixin padding($top: null, $right: null, $bottom: null, $left: null) {
	padding-top: calculateRem($top);
	padding-right: calculateRem($right);
	padding-bottom: calculateRem($bottom);
	padding-left: calculateRem($left);
}

//Margin mixin
@mixin margin($top: null, $right: null, $bottom: null, $left: null) {
	margin-top: calculateRem($top);
	margin-right: calculateRem($right);
	margin-bottom: calculateRem($bottom);
	margin-left: calculateRem($left);
}

//Border Radius mixin
@mixin borderRadius($top: null, $right: null, $bottom: null, $left: null) {
	border-radius: calculateRem($top) calculateRem($right) calculateRem($bottom) calculateRem($left);
}

//Border mixin
@mixin border($top: null, $right: null, $bottom: null, $left: null, $style: null, $color: null, $important: null) {
	border-top: calculateRem($top) $style $color $important;
	border-right: calculateRem($right) $style $color $important;
	border-bottom: calculateRem($bottom) $style $color $important;
	border-left: calculateRem($left) $style $color $important;
}

//Box Shadow
@mixin boxShadow($hoffset, $voffset, $blur, $color) {
	box-shadow: calculateRem($hoffset) calculateRem($voffset) calculateRem($blur) $color;
	-moz-box-shadow: calculateRem($hoffset) calculateRem($voffset) calculateRem($blur) $color;
	-webkit-box-shadow: calculateRem($hoffset) calculateRem($voffset) calculateRem($blur) $color;
}

/* Scroll bars */
@mixin scrollbars($size, $thumb-color, $track-color) {
	::-webkit-scrollbar {
		width: $size;
	}

	::-webkit-scrollbar-thumb {
		background: $thumb-color;

		border-radius: 10px;

		-webkit-box-shadow: none;

		-moz-box-shadow: none;

		box-shadow: none;
	}

	::-webkit-scrollbar-track {
		background: $track-color;
	}
}
