$brandColors: (
	teal: (
		50: $lighter-teal,
		100: $light-teal,
		200: $teal,
		300: $dark-teal,
		400: $darker-teal
	),
	white: (
		50: $white50,
		100: $white100,
		200: $white200,
		300: $white300,
		400: $white400,
		500: $white500,
		600: $white600
	),
	gray: (
		50: $gray50,
		100: $gray100,
		200: $gray200,
		300: $gray300,
		400: $gray400,
		500: $gray500,
		600: $gray600,
		700: $gray700,
		800: $gray800,
		900: $gray900,
		D100: $grayD100,
		D200: $o-black
	),
	coolgray: (
		50: $lighter-coolgray,
		100: $light-coolgray,
		200: $coolgray,
		300: $dark-coolgray,
		400: $darker-coolgray
	),
	yellow: (
		50: $lighter-yellow,
		100: $light-yellow,
		200: $yellow,
		300: $dark-yellow,
		400: $darker-yellow
	),
	purple: (
		50: $lighter-purple,
		100: $light-purple,
		200: $purple,
		300: $dark-purple,
		400: $darker-purple
	),
	green: (
		50: $lighter-green,
		100: $light-green,
		200: $green,
		300: $dark-green,
		400: $darker-green
	),
	orange: (
		50: $lighter-orange,
		100: $light-orange,
		200: $orange,
		300: $dark-orange,
		400: $darker-orange
	),
	red: (
		50: $lighter-red,
		100: $light-red,
		200: $red,
		300: $dark-red,
		400: $darker-red
	)
);

@mixin color-modifiers($attribute: $attribute, $prefix: '-', $separator: '-', $base: 'base') {
	@each $name, $color in $brandColors {
		&#{$prefix}#{$name} {
			@each $tone, $hex in $color {
				&#{if($tone != $base, #{$tone},
                '') } {
					#{$attribute}: $hex !important;
				}
			}
		}
	}
}
// @mixin modifiers($map, $attribute, $prefix: '-', $separator: '-', $base: 'base') {
// 	@each $key, $value in $map {
// 		&#{if($key != $base, #{$prefix}#{$key}, '')} {
// 			@if type-of($value) == 'map' {
// 				@include modifiers($value, $attribute);
// 			} @else {
// 				#{$attribute}: $value !important;
// 			}
// 		}
// 	}
// }

// @mixin btn-theme($btn-color) {
// 	border-color: darken($btn-color, 20%);
// 	background-color: $btn-color;
// 	&:hover {
// 		background-color: darken($btn-color, 6%);
// 		box-shadow: 0 .125em $off-wht, inset 0 .063em $off-wht, inset 0 -.188em lighten($btn-color, 2%);
// 	}
// 	&:active {
// 		box-shadow: 0 .063em $off-wht, inset 0 0 1.094em darken($btn-color, 40%), inset 0 .063em darken($btn-color, 20%),inset 0 -0.188em darken($btn-color, 20%);
// 	}
// }
