@import '../utils/variables.scss';
@import '../utils/mixins.scss';

.footerWrapper {
	position: relative;
	top: 0;
	left: auto;
	margin-left: 0 !important;
	margin-top: calculateRem(24);
	@include sourceSansProSemiBold(14.22);
	line-height: calculateRem(40);
	@include border(1, 1, 1, 1, solid, $lightGray);
	box-shadow: none;
	@include padding(5, null, 5, null);
	min-height: calculateRem(40);
	background: $o-gray50;
	z-index: 1;

	p {
		margin-top:.5em;
		margin-bottom: 0;
		font-weight:$semiBold;
	}

	ul {
		margin-bottom: 0;

		li {
			display: inline-block;
			margin-left: calculateRem(16);

			a {
				font-weight:$semiBold;
			}

			&:after {
				content: '';
				// @include border(1, 0, 0, 0, solid, $gray200);
				background: $white200;
				height: calculateRem(12);
				width: calculateRem(1);
				position: absolute;
				top: calculateRem(15);
				@include margin(0, 9, 0, 9);
			}
			&:last-child {
				&:after {
					content: '';
					background: none;
				}
			}
		}
	}
}
