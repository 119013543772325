#upsell-card {
  .upsell-wrapper {
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .dbCountWrapper {
    max-height: 100%;
  }

  .upsell-message {
    flex: 2 0 auto;
    text-align: center;
    padding: 0 10px;
  }

  .upsell-link {
    flex: 1 0 auto;
  }

  .upsell-big {
    font-size: 2.5rem;
    font-weight: 600;
    display: block;
  }

  @media all and (max-width: 990px) {
    .row {
      margin: 0;
    }
  }

  .bttn-accent {
    align-self: center;
  }
}
